const initialState = {
    loading: false,
    isAuthenticated: false,
    user: {},
    userList: [],
    adminList: [],
    redirect: false,
};

const Auth = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_FROM_TOKEN_REQUEST':
            return { ...state, loading: true };
        case 'USER_FROM_TOKEN_SUCCESS':
            return { ...state, loading: false, isAuthenticated: true, user: action.user };
        case 'USER_FROM_TOKEN_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_LOGIN_REQUEST':
            return { ...state, loading: true };
        case 'USER_LOGIN_SUCCESS':
            return { ...state, loading: false, isAuthenticated: true, user: action.user };
        case 'USER_LOGIN_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_LOGOUT_REQUEST':
            return { ...state, loading: true };
        case 'USER_LOGOUT_SUCCESS':
            return { ...state, loading: false, isAuthenticated: false, user: {} };
        case 'USER_LOGOUT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_REGISTER_REQUEST':
            return { ...state, loading: true };
        case 'USER_REGISTER_SUCCESS':
            return { ...state, loading: false, isAuthenticated: true, user: action.user };
        case 'USER_REGISTER_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_UPDATE_REQUEST':
            return { ...state, loading: true };
        case 'USER_UPDATE_SUCCESS':
            return { ...state, loading: false, isAuthenticated: true, user: action.user };
        case 'USER_UPDATE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_DELETE_REQUEST':
            return { ...state, loading: true };
        case 'USER_DELETE_SUCCESS':
            return { ...state, loading: false, isAuthenticated: false, user: {} };
        case 'USER_DELETE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_GET_ALL_REQUEST':
            return { ...state, loading: true };
        case 'USER_GET_ALL_SUCCESS':
            return { ...state, loading: false, userList: action.userList };
        case 'USER_GET_ALL_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_APPROVE_REQUEST':
            return { ...state, loading: true };
        case 'USER_APPROVE_SUCCESS':
            return { ...state, loading: false, userList: state.userList.map(user => user._id === action.user._id ? action.user : user) };
        case 'USER_APPROVE_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_GET_ALL_ADMIN_REQUEST':
            return { ...state, loading: true };
        case 'USER_GET_ALL_ADMIN_SUCCESS':
            return { ...state, loading: false, adminList: action.users };
        case 'USER_GET_ALL_ADMIN_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_ADD_ADMIN_REQUEST':
            return { ...state, loading: true };
        case 'USER_ADD_ADMIN_SUCCESS':
            return { ...state, loading: false, adminList: [...state.adminList, action.user], redirect: true };
        case 'USER_ADD_ADMIN_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'USER_EDIT_ADMIN_REQUEST':
            return { ...state, loading: true };
        case 'USER_EDIT_ADMIN_SUCCESS':
            return { ...state, loading: false, adminList: state.adminList.map(user => user._id === action.user._id ? action.user : user), redirect: true };
        case 'USER_EDIT_ADMIN_FAILURE':
            return { ...state, loading: false, error: action.error };

        case 'SET_REDIRECT':
            return { ...state, redirect: false };

        default:
            return state;
    }
};

export default Auth;