const Modal = (modalRef) => {
    const closeModal = () => {
        const modalElement = modalRef.current;
        if (modalElement) {
            modalElement.classList.remove("show");
            modalElement.style.display = "none";
            document.body.classList.remove("modal-open");
        }
    };

    const openModal = () => {
        const modalElement = modalRef.current;
        if (modalElement) {
            modalElement.classList.add("show");
            modalElement.classList.add("modal-open");
            modalElement.style.display = "block";
            modalElement.style.background = "rgba(0,0,0,0.5)";
            document.body.classList.add("modal-open");
        }
    };

    return { closeModal, openModal };
};

export default Modal;