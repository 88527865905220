import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;


export const getBanners = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_BANNERS_REQUEST" });
            const { data } = await axios.get(`${API_URL}/banner`);
            dispatch({ type: "GET_BANNERS_SUCCESS", banners: data });
        } catch (error) {
            dispatch({ type: "GET_BANNERS_FAILURE", error: error.message });

        }
    };
}

export const addBanner = (banner) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADD_BANNER_REQUEST" });
            const { data } = await axios.post(`${API_URL}/banner`, banner, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "ADD_BANNER_SUCCESS",
                banner: data.createdBanner,
            });
            toast.success('Banner added successfully!');
        } catch (error) {
            dispatch({ type: "ADD_BANNER_FAILURE", error: error.message });
            toast.error('Banner addition failed!');
        }
    };
}

export const deleteBanner = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "DELETE_BANNER_REQUEST" });
            await axios.delete(`${API_URL}/banner/${id}`, {
                headers: { "x-auth-token": `${localStorage.getItem("token")}` },
            });
            dispatch({ type: "DELETE_BANNER_SUCCESS", id });
            toast.success('Banner deleted successfully!');
        } catch (error) {
            dispatch({ type: "DELETE_BANNER_FAILURE", error: error.message });
            toast.error('Banner deletion failed!');
        }
    };
}

export const updateBanner = (id, banner) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_BANNER_REQUEST" });
            const { data } = await axios.put(`${API_URL}/banner/${id}`, banner, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });

            dispatch({ type: "UPDATE_BANNER_SUCCESS", banner: data });
            toast.success('Banner updated successfully!');
        } catch (error) {
            dispatch({ type: "UPDATE_BANNER_FAILURE", error: error.message });
            toast.error('Banner updation failed!');
        }
    };
}


export function setRedirecting() {
    return async dispatch => {
        dispatch({ type: 'SET_REDIRECTING' });
    }
}