import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;

export const getCategories = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_CATEGORIES_REQUEST" });
      const { data } = await axios.get(`${API_URL}/category/admin`);
      dispatch({ type: "GET_CATEGORIES_SUCCESS", categories: data });
    } catch (error) {
      dispatch({ type: "GET_CATEGORIES_FAILURE", error: error.message });
    }
  };
};

export const addCategory = (category) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "ADD_CATEGORY_REQUEST" });
      const { data } = await axios.post(`${API_URL}/category`, category, {
        headers: {
          "x-auth-token": `${localStorage.getItem("token")}`,
        },
      });
      dispatch({
        type: "ADD_CATEGORY_SUCCESS",
        category: data.createdCategory,
      });
      toast.success('Category added successfully!');
    } catch (error) {
      dispatch({ type: "ADD_CATEGORY_FAILURE", error: error.message });
    }
  };
};

export const deleteCategory = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "DELETE_CATEGORY_REQUEST" });
      await axios.delete(`${API_URL}/category/${id}`, {
        headers: { "x-auth-token": `${localStorage.getItem("token")}` },
      });
      dispatch({ type: "DELETE_CATEGORY_SUCCESS", id });
      toast.success('Category deleted successfully!');
    } catch (error) {
      dispatch({ type: "DELETE_CATEGORY_FAILURE", error: error.message });
    }
  };
};

export const updateCategory = (id, category) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_CATEGORY_REQUEST" });
      const { data } = await axios.put(`${API_URL}/category/${id}`, category, {
        headers: {
          "x-auth-token": `${localStorage.getItem("token")}`,
        },
      });
      dispatch({ type: "UPDATE_CATEGORY_SUCCESS", category: data });
      toast.success('Category updated successfully!');
    } catch (error) {
      dispatch({ type: "UPDATE_CATEGORY_FAILURE", error: error.message });
    }
  };
};

export const loadCategories = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOAD_CATEGORIES_REQUEST" });
      dispatch({ type: "LOAD_CATEGORIES_SUCCESS", categories: data });
    } catch (error) {
      dispatch({ type: "LOAD_CATEGORIES_FAILURE", error: error.message });
    }
  };
};

export const setCategoryStatus = (id, category) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_STATUS_REQUEST" });
      dispatch({ type: "UPDATE_STATUS_SUCCESS", id, category });
    } catch (error) {
      dispatch({ type: "UPDATE_STATUS_FAILURE", error: error.message });
    }
  };
}