import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';

const { addDropshipFee, updateDropshipFee } = require('../actions/dropshipFeeAction');


const DropshipFee = (props) => {
    const dispatch = useDispatch();
    const { FormName, Editable } = props;
    const { dropshipfee, loading } = useSelector(state => state.DropshipFee);
    const [dropshipfees, setDropFee] = useState({});
    const [DropshipFee, setDropshipFee] = useState({
        fees: '',
    });

    useEffect(() => {
        const dropfee = dropshipfee.find(dropshipfee => dropshipfee.id === 1);
        if (dropfee) {
            setDropFee(dropfee);
            setDropshipFee({
                fees: dropfee.fees,
            });
        }
        // eslint-disable-next-line
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDropshipFee({ ...DropshipFee, [name]: value });
    };

    const handleAddDropshipFee = (e) => {
        e.preventDefault();
        if (DropshipFee.fees === '') {
            toast.error('Please enter dropshipfee');
            return;
        } else if (DropshipFee.fees !== '') {
            dispatch(addDropshipFee(DropshipFee));
        }
    }

    const handleUpdateDropshipFee = (e) => {
        e.preventDefault();
        dispatch(updateDropshipFee(dropshipfees._id, DropshipFee));
    }


    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    loading ? (<PulseLoader />) : (
                        <div className="row p-5">
                            <div className="col-md-3">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">{FormName}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <InputField label="Fees" name='fees' value={DropshipFee.fees} onChange={handleInputChange} required={true} type='number' placeholder="Fees here... Ex. 10" />
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                Editable === 'true' ? <button type="submit" onClick={handleUpdateDropshipFee} className="btn btn-primary" disabled={loading ? true : false}>
                                                    Save
                                                </button> : <button type="submit" onClick={handleAddDropshipFee} className="btn btn-primary" disabled={loading ? true : false}>
                                                    Save
                                                </button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default DropshipFee