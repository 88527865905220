const model = {
    models: [],
    loading: false,
    error: null,
}


const Models = (state = model, action) => {
    switch (action.type) {
        case "GET_MODELS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_MODELS_SUCCESS":
            return {
                ...state,
                loading: false,
                models: action.models,
            };
        case "GET_MODELS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "ADD_MODEL_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "ADD_MODEL_SUCCESS":
            return {
                ...state,
                loading: false,
                models: [...state.models, action.model],
            };
        case "ADD_MODEL_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "DELETE_MODEL_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_MODEL_SUCCESS":
            return {
                ...state,
                loading: false,
                models: state.models.filter(
                    (model) => model._id !== action.id
                ),
            };
        case "DELETE_MODEL_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "UPDATE_MODEL_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_MODEL_SUCCESS":
            return {
                ...state,
                loading: false,
                models: state.models.map((model) => model._id === action.model._id ? action.model : model),
            };
        case "UPDATE_MODEL_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "UPDATE_MODEL_STATUS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_MODEL_STATUS_SUCCESS":
            return {
                ...state,
                loading: false,
                models: state.models.map((model) => model._id === action.id ? action.model : model),
            };
        case "UPDATE_MODEL_STATUS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};

export default Models;