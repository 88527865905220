import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import MultipleImageField from "../components/InputFields/MultipleImageField";
import { addAD, updateAD, setRedirecting } from '../actions/adsAction';

const AdForm = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { FormName, Editable } = props;
    const { ads, redirect, loading } = useSelector(state => state.Ad);
    const [banner, setBan] = useState({});
    const [Banner, setBanner] = useState({
        url: '',
        position: '',
        photo: [],
    });

    const [fileLoading, setFileLoading] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (id) {
            // eslint-disable-next-line
            const bann = ads.find(banner => banner.id == id);
            if (bann) {
                setBan(bann);
                setBanner({
                    url: bann.url,
                    position: bann.position,
                    photo: bann.photo
                });
            }
        }
        // eslint-disable-next-line
    }, [id, ads]);

    useEffect(() => {
        if (redirect) {
            navigate('/ads-spots');
        }
        dispatch(setRedirecting())
        // eslint-disable-next-line
    }, [redirect]);

    const [photoSize, setPhotoSize] = useState('');
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBanner({ ...Banner, [name]: value });
        if (name === 'position' && value === 'miniad') {
            setPhotoSize('600 X 600 px');
        } else {
            setPhotoSize('1920 X 600 px');
        }
    };
    const handleAddBanner = (e) => {
        e.preventDefault();
        const found = ads.some(el => el.position === Banner.position);
        if (found) {
            toast.error('Ad already exist in this position please do update');
            return;
        }
        if (Banner.url === '') {
            toast.error('Please enter ad url');
            return;
        } else if (Banner.url !== '') {
            Banner.photo = files;
            dispatch(addAD(Banner));
        }
    }
    // eslint-disable-next-line
    const [position, setPosition] = useState([
        {
            option: 'Ad Spot 1',
            value: 'adspot1'
        },
        {
            option: 'Ad Spot 2',
            value: 'adspot2'
        },
        {
            option: 'Ad Spot 3',
            value: 'adspot3'
        },
        {
            option: 'Ad Spot 4',
            value: 'adspot4'
        },
        {
            option: 'Mini Ad',
            value: 'miniad'
        }
    ]);

    // eslint-disable-next-line
    const editPosition = [
        {
            option: 'Ad Spot 1',
            value: 'adspot1'
        },
        {
            option: 'Ad Spot 2',
            value: 'adspot2'
        },
        {
            option: 'Ad Spot 3',
            value: 'adspot3'
        },
        {
            option: 'Ad Spot 4',
            value: 'adspot4'
        },
        {
            option: 'Mini Ad',
            value: 'miniad'
        }
    ];

    useEffect(() => {
        if (ads.length > 0) {
            ads.forEach(ad => {
                if (ad.position === 'miniad') {
                    return;
                }
                else {
                    const found = position.some(el => el.value === ad.position);
                    if (found) {
                        const index = position.findIndex(x => x.value === ad.position);
                        position.splice(index, 1);
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, [ads]);

    const handleUpdateBanner = (e) => {
        e.preventDefault();
        Banner.photo = files;
        dispatch(updateAD(banner._id, Banner));
    }

    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    loading ? (<PulseLoader />) : (
                        <div className="row p-5 justify-content-center">
                            <div className="col-md-5">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">{FormName}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <div className='d-flex flex-column'>
                                                            <label htmlFor='position' className="form-label">Page</label>
                                                            <select label="URL" name='position' onChange={handleInputChange} value={
                                                                Banner.position
                                                            } disabled={id ? true : false}>
                                                                <option value="">Select Position</option>
                                                                {
                                                                    id ? (
                                                                        editPosition.map((pos, index) => (
                                                                            <option key={index} value={pos.value}>{pos.option}</option>
                                                                        ))
                                                                    ) : (
                                                                        position.map((pos, index) => (
                                                                            <option key={index} value={pos.value}>{pos.option}</option>
                                                                        ))
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <InputField label="URL" name='url' value={Banner.url} onChange={handleInputChange} required={true} type='text' placeholder="https://remaxwireless.com/shop" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <MultipleImageField name="photos" setFiles={setFiles} data={Banner.photo} setFileLoading={setFileLoading} size={photoSize} />
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                Editable === 'true' ? <button type="submit" onClick={handleUpdateBanner} className="btn btn-primary" disabled={fileLoading ? true : false}>
                                                    Update Ad
                                                </button> : <button type="submit" onClick={handleAddBanner} className="btn btn-primary" disabled={fileLoading ? true : false}>
                                                    Save
                                                </button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div >
                    )
                }
                <div className="content-backdrop fade"></div>
            </div >
        </>
    )
}

export default AdForm