const RetailerOrderSummary= ({ data }) => {
    const { stores } = data;
    const calculateOrderTotal = (stores) =>
      stores.reduce((total, store) => total + store.totalItemsPrice, 0);
  
    return (
      <div className="order-details mt-5 bg-white p-6 rounded-lg shadow-lg">
        {stores.map((store, storeIndex) => (
          <div key={storeIndex} className="mb-8">
            <h5 className="text-lg font-semibold text-gray-800 mb-4">
              Store - {store.storeName}
            </h5>
            <div className="space-y-2">
              {store.cartItems.map((item, itemIndex) => (
                <div key={itemIndex} className="flex justify-between">
                  <span className="text-gray-700">{item.name}</span>
                  <span className="text-gray-700">
                    {item.itemPrice} x {item.quantity} ={' '}
                    <span className="font-semibold">
                      {(item.itemPrice * item.quantity).toFixed(2)}
                    </span>
                  </span>
                </div>
              ))}
            </div>
            <div className="store-total font-bold text-right text-indigo-600 mt-4">
              Total: {store.totalItemsPrice.toFixed(2)}
            </div>
            <hr className="mt-4" />
          </div>
        ))}
        <div className="total text-right font-bold text-2xl text-indigo-700 mt-8">
          Grand Total: {parseFloat(calculateOrderTotal(stores)).toFixed(2)}
        </div>
      </div>
    );
  };
  
  export default RetailerOrderSummary;