import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../actions/authActions'
import { syncnow } from '../actions/syncAction'
import { useEffect } from 'react'
import axios from 'axios';
import moment from 'moment'

const Navbar = () => {
    const { user } = useSelector(state => state.Auth)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [lastSync, setLastSync] = useState('');
    const host = process.env.REACT_APP_API_HOST;


    const getLastSync = async () => {
        const { data } = await axios.get(`${host}/posinven/lastSync`);
        const date = moment(data[0].lastSync).calendar()
        setLastSync(date);
    }

    const handleSyncNow = () => {
        setLoading(true)
        dispatch(syncnow())
    }

    useEffect(() => {
        getLastSync();

        // eslint-disable-next-line
    }, []);


    return (
        <>
            <header className="header">
                <div className="header__container">
                    <div className="navbar-nav align-items-center">
                        <div className="nav-item d-flex justify-content-end">
                            <i class='bx bx-check-shield' style={{
                                fontSize: '1.5rem',
                                color: '#71dd37',
                                display: 'flex',
                                alignItems: 'center',
                            }}></i>
                            {
                                lastSync && <span className="badge  bg-success pt-3 mx-3">Last Sync: {lastSync}</span>
                            }
                            <button className='btn btn-primary' onClick={handleSyncNow} disabled={loading} >
                                Sync Now
                            </button>
                        </div>
                    </div>
                    <div className="nav-item navbar-dropdown dropdown-user dropdown">
                        <a className="nav-link dropdown-toggle hide-arrow" href='/#' data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="avatar avatar-online">
                                <img src="../assets/img/avatars/1.png" alt="" className="w-px-40 h-auto rounded-circle" />
                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <a className="dropdown-item" href="/#">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar avatar-online">
                                                <img src="../assets/img/avatars/1.png" alt="" className="w-px-40 h-auto rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="fw-semibold d-block">{user.firstName}</span>
                                            <small className="text-muted">Admin</small>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            {/* <li>
                                    <div className="dropdown-divider"></div>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/#">
                                        <i className="bx bx-user me-2"></i>
                                        <span className="align-middle">My Profile</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/#">
                                        <i className="bx bx-cog me-2"></i>
                                        <span className="align-middle">Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/#">
                                        <span className="d-flex align-items-center align-middle">
                                            <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                                            <span className="flex-grow-1 align-middle">Billing</span>
                                            <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                                        </span>
                                    </a>
                                </li> */}
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <span className="dropdown-item" onClick={() => dispatch(userLogout())} >
                                    <i className="bx bx-power-off me-2"></i>
                                    <span className="align-middle">Log Out</span>
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className="header__toggle">
                        <i className='bx bx-menu' id="header-toggle"></i>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Navbar