import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;


export const getDesigns = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_DESIGNS_REQUEST" });
            const { data } = await axios.get(`${API_URL}/design`);
            dispatch({ type: "GET_DESIGNS_SUCCESS", designs: data });
        } catch (error) {
            dispatch({ type: "GET_DESIGNS_FAILURE", error: error.message });

        }
    };
}

export const addDesign = (design) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADD_DESIGN_REQUEST" });
            const { data } = await axios.post(`${API_URL}/design`, design, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "ADD_DESIGN_SUCCESS",
                design: data.createdDesign,
            });
            toast.success('Design added successfully!');
        } catch (error) {
            dispatch({ type: "ADD_DESIGN_FAILURE", error: error.message });
            toast.error('Design addition failed!');
        }
    };
}

export const deleteDesign = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "DELETE_DESIGN_REQUEST" });
            await axios.delete(`${API_URL}/design/${id}`, {
                headers: { "x-auth-token": `${localStorage.getItem("token")}` },
            });
            dispatch({ type: "DELETE_DESIGN_SUCCESS", id });
            toast.success('Design deleted successfully!');
        } catch (error) {
            dispatch({ type: "DELETE_DESIGN_FAILURE", error: error.message });
            toast.error('Design deletion failed!');
        }
    };
}

export const updateDesign = (id, design) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_DESIGN_REQUEST" });
            const { data } = await axios.put(`${API_URL}/design/${id}`, design, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "UPDATE_DESIGN_SUCCESS",
                design: data,
            });
            toast.success('Design updated successfully!');
        } catch (error) {
            dispatch({ type: "UPDATE_DESIGN_FAILURE", error: error.message });
            toast.error('Design updation failed!');
        }
    };
}


export function setRedirecting() {
    return async dispatch => {
        dispatch({ type: 'SET_REDIRECTING' });
    }
}