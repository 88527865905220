const initialState = {
    loading: false,
    designs: [],
    error: null,
};

const Design = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DESIGNS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_DESIGNS_SUCCESS":
            return {
                ...state,
                loading: false,
                designs: action.designs,
            };
        case "GET_DESIGNS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "ADD_DESIGN_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "ADD_DESIGN_SUCCESS":
            return {
                ...state,
                loading: false,
                designs: [...state.designs, action.design],
                redirect: true,
            };
        case "ADD_DESIGN_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "DELETE_DESIGN_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_DESIGN_SUCCESS":
            return {
                ...state,
                loading: false,
                designs: state.designs.filter(
                    (design) => design._id !== action.id
                ),
            };
        case "DELETE_DESIGN_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "UPDATE_DESIGN_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_DESIGN_SUCCESS":
            return {
                ...state,
                loading: false,
                designs: state.designs.map((des) => des.id === action.design.id ? action.design : des),
                redirect: true,
            };
        case "UPDATE_DESIGN_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "SET_REDIRECTING":
            return {
                ...state,
                redirect: false,
            };
        default:
            return state;
    }
};

export default Design;