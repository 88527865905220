import React, { useState, useEffect } from 'react';
import './MultiImage.css';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import PulseLoader from '../PulseLoader';
import { toast } from 'react-hot-toast';
import InputField from "../InputFields/InputField";

const MultipleImageFieldForAdd = ({ name, setFileImages, size,setUrlImages}) => {
    const [previewImages, setPreviewImages] = useState([]);
    const [urlFiles, setUrlFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [url,setUrl]=useState("");
    
    const [loading, setLoading] = useState(false);
    const  addUrl=()=>{
        let updatedUrlImages=[...urlFiles,url];
        setUrlImages(updatedUrlImages);
        setUrl("");
        setUrlFiles(updatedUrlImages);
    }
    const handleFileChange = async (e) => {
        const selectedFiles = Array.from(e.target.files);
        const filesToUpload=selectedFiles.map((file)=>file);
        setFiles((file)=>[...file,...filesToUpload]);
        setFileImages(filesToUpload);
        const imageUrls = selectedFiles.map((file) => URL.createObjectURL(file));
        setPreviewImages((previewImage) => [...previewImage, ...imageUrls]);
    };

    const handleRemoveImage = (image, i) => {
        const updatedImages = [...previewImages];
        const tempFiles=[...files]
        tempFiles.splice(i,1);
        updatedImages.splice(i, 1);
        setFiles(tempFiles);
        setFileImages(tempFiles)
        setPreviewImages(updatedImages);
    };

    const handleRemoveUrlImage=(image,i)=>{
      const updatedUrlImages = [...urlFiles];
      updatedUrlImages.splice(i,1);
      setUrlFiles(updatedUrlImages);
      setUrlFiles(updatedUrlImages)
      
    }

    return (
        <div className=''>
            <div className="mb-3">
                <label className='form-label'> Upload Images ({size})</label>
                <input className="form-control drop-area" name={name} onChange={handleFileChange} type="file" accept="image/*, video/*" multiple />
            </div>
            <div className="image-preview">
                {loading ? <div className='loaderImage'> <PulseLoader /> </div> :
                    <>
                        {previewImages.map((image, index) => (

                            <div key={index} className="image-preview-item">
                                    <img src={image} alt={`Product ${index + 1}`} />
                                     <i className="bx bx-x" onClick={() => handleRemoveImage(image, index)}></i>
                            </div>
                        ))}
                        {urlFiles.map((image, index) => (

                            <div key={index} className="image-preview-item">
                                    <img src={image} alt={`Product ${index + 1}`} />
                                     <i className="bx bx-x" onClick={() => handleRemoveUrlImage(image, index)}></i>
                            </div>
                        ))}
                    </>
                }
            </div>
            <div className="row">
                    <h3 className="text-center">Or</h3>
            </div>
             <div className="row">
                    <div className="col-md-8">
                      <div className="mb-3">
                        <InputField
                          label="Image URL"
                          name="imgURL"
                          value={url}
                          onChange={(e) => { setUrl(e.target.value) }}
                          type="text"
                          placeholder="Product Image URL"
                        />
                      </div>
                    </div>
                    <div className="col-md-2 mt-1">
                      <div className="mt-4">
                        <button className="btn btn-outline-primary" onClick={addUrl}>
                          Add URL
                        </button>
                      </div>
                    </div>
            </div>
        </div>
    );
};

export default MultipleImageFieldForAdd;