import axios from 'axios';
import { toast } from "react-hot-toast";
import ReactQuill from 'react-quill';
import { React, useEffect, useState } from "react";
import Select from "react-select";
import InputField from "../components/InputFields/InputField";
import PulseLoader from "../components/PulseLoader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import MultipleImageFieldForEdit from "../components/InputFields/MultipleImageFieldForEdit";
import MultipleImageFieldForAdd from "../components/InputFields/MultipleImageFieldForAdd";
const { addProduct, updateProduct } = require("../actions/productActions");

const ProductForm = ({FormName, Editable, status}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading,redirect} = useSelector((state) => state.Product);
  const { categories } = useSelector((state) => state.Category);
  const { subCategories } = useSelector((state) => state.SubCategory);
  const { brands } = useSelector((state) => state.Brand);
  const { models } = useSelector((state) => state.Models);
  const { carriers } = useSelector((state) => state.Carrier);
  const { designs } = useSelector((state) => state.Design);
  const [fileLoading, setFileLoading] = useState(false);
  // const { tags } = useSelector((state) => state.Tag);
  const [subCatOP, setSubCatOP] = useState([]);
  const [modelOP, setModelOP] = useState([]);
  const [quillValue, setQuillValue] = useState('');
  const [fileImages, setFileImages] = useState([]);
  const [urlImages, setUrlImages] = useState([]);
  const [pro, setPro] = useState({});
  const [Product, setProduct] = useState({
    name: "",
    category: "",
    subcategory: "",
    skuNumber: "",
    price: "",
    consumerDiscount: "",
    retailerPrice: "",
    retailerDiscount: "",
    dropshipperPrice: "",
    dropshipperDiscount: "",
    stock: "",
    description: "",
    barCode: "",
    height: "",
    width: "",
    length: "",
    weight: "",
    discount: "",
    bestSeller: "",
    brand: "",
    carrier: "",
    design: "",
    model: "",
    color: "",
    photos: [],
    clearance: "",
    newArrival: "",
    status: status,
    fileImages:[],
    urlImages:[],
  });
  
  const [Loading,setLoading]=useState(false)
  
  useEffect(() => {
    if (id) {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_HOST}/products/admin-product/${id}`,{
          headers: {
              'Content-Type': 'multipart/form-data',
              'x-auth-token': localStorage.getItem('token')
          }
      }).then(res => {
        const fetchedProduct=res.data;
        setPro(fetchedProduct);
        Product.name = fetchedProduct.name;
        Product.category = fetchedProduct.category?._id;
        Product.subcategory = fetchedProduct.subcategory?._id;
        Product.skuNumber = fetchedProduct.skuNumber;
        Product.barCode = fetchedProduct.barCode;
        Product.price = fetchedProduct.price;
        Product.consumerDiscount = fetchedProduct.consumerDiscount;
        Product.retailerPrice = fetchedProduct.retailerPrice;
        Product.retailerDiscount = fetchedProduct.retailerDiscount;
        Product.name = fetchedProduct.name;
        Product.category = fetchedProduct.category?._id;
        Product.subcategory = fetchedProduct.subcategory?._id;
        Product.skuNumber = fetchedProduct.skuNumber;
        Product.barCode = fetchedProduct.barCode;
        Product.price = fetchedProduct.price;
        Product.consumerDiscount = fetchedProduct.consumerDiscount;
        Product.retailerPrice = fetchedProduct.retailerPrice;
        Product.retailerDiscount = fetchedProduct.retailerDiscount;
        Product.dropshipperPrice = fetchedProduct.dropshipperPrice;
        Product.dropshipperDiscount = fetchedProduct.dropshipperDiscount;
        Product.stock = fetchedProduct.stock;
        Product.bestSeller = fetchedProduct.bestSeller;
        Product.brand = fetchedProduct.brand?._id;
        Product.model = fetchedProduct.model?._id;
        Product.design = fetchedProduct.design?._id;
        Product.carrier = fetchedProduct.carrier?._id;
        Product.color = fetchedProduct.color;
        Product.weight = fetchedProduct.weight;
        Product.height = fetchedProduct.height;
        Product.width = fetchedProduct.width;
        Product.length = fetchedProduct.length;
        Product.discount = fetchedProduct.discount;
        Product.description = fetchedProduct.description;
        Product.photos = fetchedProduct.images;
        Product.status = fetchedProduct.status;
        Product.clearance = fetchedProduct.clearance;
        Product.newArrival = fetchedProduct.newArrival;
        Product.images=fetchedProduct?.images||[];
        Product.urlImages=fetchedProduct?.urlImages||[];
        setQuillValue(fetchedProduct.description);
        setLoading(false);
      }
      ).catch(err => {
          setLoading(false);
          toast.error(`Failed to fetch product with id:${id}`);
      })
      }
    

    // eslint-disable-next-line
  }, [id,loading]);

  // useEffect(() => {
  //   if (redirect) {
  //     navigate("/products");
  //   }
  //   // eslint-disable-next-line
  // }, [redirect]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...Product, [name]: value });
  };

  const selectCatgeoryOptions = categories.map((category) => {
    return { value: category._id, label: category.name };
  });

  const handleCategory = (e) => {
    setProduct({
      ...Product,
      category: e.value,
      subcategory: null,
    });
    const subCat = subCategories.filter((subCategory) => {
      return subCategory.category._id === e.value;
    });

    const subCatOptions = subCat.map((subCategory) => {
      return { value: subCategory._id, label: subCategory.name };
    });
    setSubCatOP(subCatOptions);
  };

  const handleSubCategory = (e) => {
    setProduct({
      ...Product,
      subcategory: e.value,
    });
  };

  useEffect(() => {
    const subCat = subCategories.filter((item) => {
      return item?.category._id === pro?.category?._id;
    });

    const subCatOptions = subCat.map((subCategory) => {
      return { value: subCategory._id, label: subCategory.name };
    });

    setSubCatOP(subCatOptions);


    const mod = models.filter((model) => {
      return model.brand?._id === pro?.brand?._id;
    });

    const modelOptions = mod.map((model) => {
      return { value: model._id, label: model.name };
    });
    setModelOP(modelOptions);

    // eslint-disable-next-line
  }, [id, pro]);


  const selectBrand = brands.map((brand) => {
    return { value: brand._id, label: brand.name };
  });

  const handleBrand = (e) => {
    setProduct({
      ...Product,
      brand: e.value,
    });

    const mod = models.filter((model) => {
      return model?.brand?._id === e.value;
    });


    const modelOptions = mod.map((model) => {
      return { value: model._id, label: model.name };
    });

    console.log(modelOptions)
    setModelOP(modelOptions);
  };

  const handleModel = (e) => {
    setProduct({
      ...Product,
      model: e.value,
    });
  };

  // useEffect(() => {
  //   const mod = models.filter((model) => {
  //     console.log(model.brand?._id, Product.brand?._id);
  //     return model.brand?._id === Product.brand?._id;
  //   });

  //   const modelOptions = mod.map((model) => {
  //     return { value: model._id, label: model.name };
  //   });
  //   setModelOP(modelOptions);
  //   // eslint-disable-next-line
  // }, [Product.brand])

  const selectDesign = designs.map((design) => {
    return { value: design._id, label: design.name };
  });

  const handleDesign = (e) => {
    setProduct({
      ...Product,
      design: e.value,
    });
  };

  const selectCarrier = carriers.map((carrier) => {
    return { value: carrier._id, label: carrier.name };
  });

  const handleCarrier = (e) => {
    setProduct({
      ...Product,
      carrier: e.value,
    });
  };

  const selectVisibilityStatus = [
    { value: "Draft", label: "Draft" },
    { value: "Published", label: "Publish" },
    { value: "Unpublished", label: "Unpublish" }
  ];

  const handleVisibilityStatus = (e) => {
    setProduct({
      ...Product,
      status: e.value,
    });
  };

  const selectBestSeller = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ]

  const handleClearance = (e) => {
    setProduct({
      ...Product,
      clearance: e.value,
    });
  };

  const handleNewArrival = (e) => {
    setProduct({
      ...Product,
      newArrival: e.value,
    });
  };
  const handleBestSeller = (e) => {
    setProduct({
      ...Product,
      bestSeller: e.value,
    });
  };

  const handleAddProduct = (e) => {
    e.preventDefault();
    Product.description = quillValue;
    if (Product.name !== '' && Product.category !== '' && Product.price !== '' && Product.barCode !== '' && Product.brand !== '' && Product.model !== '' && Product.design !== '' && Product.retailerPrice !== '' && Product.dropshipperPrice !== '') {
      Product.photos = fileImages;
      Product.urlImages=urlImages;
      dispatch(addProduct(Product))
    } else {
      toast.error("Please fill all required fields");
    }
  };

  const handleUpdateProduct = (e) => {
    e.preventDefault();
    Product.description = quillValue;
    if (Product.name !== '' && Product.category !== '' && Product.price !== '' && Product.barCode !== '' && Product.brand !== '' && Product.model !== '' && Product.design !== '' && Product.retailerPrice !== '' && Product.dropshipperPrice !== '') {
      dispatch(updateProduct(pro._id, Product))
    } else {
      toast.error("Please fill all required fields");
    }
  };


  return (
    <>
      <div className="content-wrapper container-fluid">
        {Loading ? (
          <PulseLoader />
        ) : (
          <div className="p-4">
            <div className="d-flex justify-content-between align-items-center mb-3 p-2">
              <h4 className="mb-0">{FormName}</h4>
              {
                Editable === 'true' ? <button type="submit" onClick={handleUpdateProduct} className="btn btn-outline-primary" disabled={fileLoading ? true : false}>
                  Update Product
                </button> : <button type="submit" onClick={handleAddProduct} className="btn btn-outline-primary" disabled={fileLoading ? true : false}>
                  Save Product
                </button>
              }
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div className="row p-4">
                    <h5>Product Information</h5>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <InputField
                          label="Name"
                          name="name"
                          value={Product.name}
                          onChange={handleInputChange}
                          required={true}
                          type="text"
                          placeholder="Name ..."
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label required"
                          htmlFor="basic-default-fullname"
                        >
                          Category
                        </label>
                        <Select
                          options={selectCatgeoryOptions}
                          onChange={handleCategory}
                          value={
                            Product.category === null
                              ? ""
                              : selectCatgeoryOptions.find((option) => {
                                return (
                                  option.value === Product.category
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Sub Category
                        </label>
                        <Select
                          options={subCatOP}
                          onChange={handleSubCategory}
                          value={Product.subcategory === null ? "" : subCatOP.find((option) => { return (option.value === Product.subcategory); })}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <InputField
                          label="Sku Number"
                          name="skuNumber"
                          value={Product.skuNumber}
                          onChange={handleInputChange}
                          type="text"
                          placeholder="Sku Number  ..."
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <InputField
                          label="Bar Code"
                          name="barCode"
                          value={Product.barCode}
                          onChange={handleInputChange}
                          type="string"
                          required={true}
                          placeholder="Bar Code "
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <InputField
                          label="Stock"
                          name="stock"
                          value={Product.stock}
                          onChange={handleInputChange}
                          type="number"
                          placeholder="Stock ..."
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Best Seller
                        </label>
                        <Select
                          options={selectBestSeller}
                          onChange={handleBestSeller}
                          value={
                            Product.bestSeller === null
                              ? ""
                              : selectBestSeller.find((option) => {
                                return (
                                  option.value === Product.bestSeller
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Clearance
                        </label>
                        <Select
                          options={selectBestSeller}
                          onChange={handleClearance}
                          value={
                            Product.clearance === null
                              ? ""
                              : selectBestSeller.find((option) => {
                                return (
                                  option.value === Product.clearance
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          New Arrival
                        </label>
                        <Select
                          options={selectBestSeller}
                          onChange={handleNewArrival}
                          value={
                            Product.newArrival === null
                              ? ""
                              : selectBestSeller.find((option) => {
                                return (
                                  option.value === Product.newArrival
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card">
                  <div className="row p-4">
                    <h5>Product Pricing</h5>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <InputField
                          label="Consumer Price"
                          name="price"
                          value={Product.price}
                          onChange={handleInputChange}
                          required={true}
                          type="number"
                          placeholder="Consumer Price ..."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <InputField
                          label="Consumer Discount (%)"
                          name="consumerDiscount"
                          value={Product.consumerDiscount}
                          onChange={handleInputChange}
                          required={true}
                          type="number"
                          placeholder="Consumer Discount ..."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <InputField
                          label="Retailer Price"
                          name="retailerPrice"
                          value={Product.retailerPrice}
                          onChange={handleInputChange}
                          required={true}
                          type="number"
                          placeholder="Retailer Price ..."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <InputField
                          label="Retailer Discount (%)"
                          name="retailerDiscount"
                          value={Product.retailerDiscount}
                          onChange={handleInputChange}
                          required={true}
                          type="number"
                          placeholder="Retailer Discount ..."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <InputField
                          label="Dropshipper Price"
                          name="dropshipperPrice"
                          value={Product.dropshipperPrice}
                          onChange={handleInputChange}
                          required={true}
                          type="number"
                          placeholder="Dropshipper Price ..."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <InputField
                          label="Dropshipper Discount (%)"
                          name="dropshipperDiscount"
                          value={Product.dropshipperDiscount}
                          onChange={handleInputChange}
                          required={true}
                          type="number"
                          placeholder="Dropshipper Discount ..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card mb-4">
                  <div className="row p-4">
                    <h5>Product Attributes</h5>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label required"
                          htmlFor="basic-default-fullname"
                        >
                          Brand
                        </label>
                        <Select
                          options={selectBrand}
                          onChange={handleBrand}
                          value={
                            Product.brand === null
                              ? ""
                              : selectBrand.find((option) => {
                                return (
                                  option.value === Product.brand
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label required" htmlFor="basic-default-fullname">Model</label>
                        <Select
                          options={modelOP}
                          onChange={handleModel}
                          value={
                            Product.model === null
                              ? ""
                              : modelOP.find((option) => {
                                return (option.value === Product.model
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label required"
                          htmlFor="basic-default-fullname"
                        >
                          Design
                        </label>
                        <Select
                          options={selectDesign}
                          onChange={handleDesign}
                          value={
                            Product.design === undefined
                              ? ""
                              : selectDesign.find((option) => {
                                return (
                                  option.value === Product.design
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Carrier
                        </label>
                        <Select
                          options={selectCarrier}
                          onChange={handleCarrier}
                          value={
                            Product.carrier === undefined
                              ? ""
                              : selectCarrier.find((option) => {
                                return (
                                  option.value === Product.carrier
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <InputField
                          label="Color"
                          name="color"
                          value={Product.color}
                          onChange={handleInputChange}
                          type="text"
                          placeholder="Product Color"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Status
                        </label>
                        <Select
                          options={selectVisibilityStatus}
                          onChange={handleVisibilityStatus}
                          value={
                            Product.status === undefined
                              ? ""
                              : selectVisibilityStatus.find((option) => {
                                return (
                                  option.value === Product.status
                                );
                              })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4">

                  <div className="row p-4">
                    <h5>Product Dimensions</h5>
                    <div className="col-md-6">
                      <div className="mb-">
                        <InputField
                          label="Weight (lbs)"
                          name="weight"
                          value={Product.weight}
                          onChange={handleInputChange}
                          type="number"
                          placeholder="Product Weight"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <InputField
                          label="Height (in&acute;&acute;)"
                          name="height"
                          value={Product.height}
                          onChange={handleInputChange}
                          type="number"
                          placeholder="Product Height"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <InputField
                          label="Width (in&acute;&acute;)"
                          name="width"
                          value={Product.width}
                          onChange={handleInputChange}
                          type="number"
                          placeholder="Product Width"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <InputField
                          label="Length (in&acute;&acute;)"
                          name="length"
                          value={Product.length}
                          onChange={handleInputChange}
                          type="number"
                          placeholder="Product Length"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="row justify-content-center">

              <div className="col-md-12">
                <div className="card mb-4 p-4">

                  <h5>Product Description</h5>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-fullname"
                      >
                        Description
                      </label>
                      <ReactQuill theme="snow" value={quillValue} onChange={setQuillValue} placeholder={'Description ...'} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      {Editable==='true'?(<MultipleImageFieldForEdit name="photos"  fileImages={Product.images} urlImages={Product.urlImages} size="1200 x 1200 px" productId={id} />)
                      :<MultipleImageFieldForAdd name="photos"  setFileImages={setFileImages} setUrlImages={setUrlImages} size="1200 x 1200 px" />}
                    </div>
                  </div> 
                  
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default ProductForm;