const initialState = {
    loading: false,
    taxes: [],
    error: "",
    redirect: false,
};


const Tax = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TAXES_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_TAXES_SUCCESS":
            return {
                ...state,
                loading: false,
                taxes: action.taxes,
            };
        case "GET_TAXES_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "ADD_TAX_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "ADD_TAX_SUCCESS":
            return {
                ...state,
                loading: false,
                taxes: [...state.taxes, action.tax],
                redirect: true,
            };
        case "ADD_TAX_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "DELETE_TAX_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_TAX_SUCCESS":
            return {
                ...state,
                loading: false,
                taxes: state.taxes.filter(
                    (tax) => tax._id !== action.id
                ),
            };
        case "DELETE_TAX_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "UPDATE_TAX_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_TAX_SUCCESS":
            return {
                ...state,
                loading: false,
                taxes: state.taxes.map((tax) =>
                    tax.id === action.tax.id ? action.tax : tax
                ),
                redirect: true,
            };
        case "UPDATE_TAX_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "SET_REDIRECTING":
            return {
                ...state,
                redirect: false,
            };
        default:
            return state;
    }
};

export default Tax;