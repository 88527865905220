const initialState = {
    loading: false,
    ads: [],
    error: "",
    redirect: false,
};

const Ad = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ADS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_ADS_SUCCESS":
            return {
                ...state,
                loading: false,
                ads: action.ads,
            };
        case "GET_ADS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "ADD_AD_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "ADD_AD_SUCCESS":
            return {
                ...state,
                loading: false,
                ads: [...state.ads, action.ad],
                redirect: true,
            };
        case "ADD_AD_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "DELETE_AD_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_AD_SUCCESS":
            return {
                ...state,
                loading: false,
                ads: state.ads.filter(
                    (ad) => ad._id !== action.id
                ),
            };
        case "DELETE_AD_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "UPDATE_AD_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_AD_SUCCESS":
            return {
                ...state,
                loading: false,
                ads: state.ads.map((ad) =>
                    ad.id === action.ad.id ? action.ad : ad
                ),
                redirect: true,
            };
        case "UPDATE_AD_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "SET_REDIRECTING":
            return {
                ...state,
                redirect: false,
            };
        default:
            return state;
    }
};

export default Ad;