import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/index';
import { loadState } from '../utils/Persistor';

const persistedState = loadState();

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
});

