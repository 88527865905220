import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const CustomerPdf = () => {

    const { id } = useParams();
    const { userList } = useSelector(state => state.Auth);
    const user = userList.filter(user => user._id === id);

    const downloadPDF = () => {
        const input = document.getElementById('pdf-element');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'px', 'a4');
                // var width = pdf.internal.pageSize.getWidth();
                // var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                pdf.save(`${user[0].firstName}.pdf`);
            })
            ;
    }


    return (
        <>
            <div className="content-wrapper container-fluid">
                <div className='mb-3'>
                    <button className='btn btn-primary ' onClick={downloadPDF}>
                        Download as PDF
                    </button>
                </div>

                <div className="card mb-3" >
                    <div className="card-body" id="pdf-element">
                        <h2 className="card-title">Customer Details</h2>
                        <div className="row mt-4 ">
                            <div className="col-md-4 mx-2">
                                <p>
                                    <strong>First Name:</strong> {user[0].firstName}
                                </p>
                                <p>
                                    <strong>Email:</strong> {user[0].email}
                                </p>
                                <p>
                                    <strong>Business Type:</strong> {user[0].businessType}
                                </p>
                                <p>
                                    <strong>Reg State:</strong> {user[0].regState}
                                </p>
                                <p>
                                    <strong>In Business Since:</strong> {user[0].inBusinessSince}
                                </p>
                                <p>
                                    <strong>City:</strong> {user[0].city}
                                </p>
                                <p>
                                    <strong>Zip Code:</strong> {user[0].zipCode}
                                </p>
                                <p>
                                    <strong>Fed Tax Id:</strong> {user[0].fedTaxId}
                                </p>
                                <p>
                                    <strong>JBT Id:</strong> {user[0].jbtId}
                                </p>
                                <p>
                                    <strong>Customer Type:</strong> {user[0].type}
                                </p>


                                <p>
                                    <strong>Physical Address:</strong> {user[0].physicalAddress}
                                </p>
                            </div>
                            <div className="col-md-5 mx-2">
                                <p>
                                    <strong>Last Name:</strong> {user[0].lastName}
                                </p>
                                <p>
                                    <strong>Tel Business:</strong> {user[0].telBusiness}
                                </p>
                                <p>
                                    <strong>Company Name:</strong> {user[0].companyName}
                                </p>
                                <p>
                                    <strong>Trade Name:</strong> {user[0].tradeName}
                                </p>
                                <p>
                                    <strong>Shop Number:</strong> {user[0].shopNumber}
                                </p>
                                <p>
                                    <strong>State:</strong> {user[0].state}
                                </p>
                                <p>
                                    <strong>Fax:</strong> {user[0].fax}
                                </p>
                                <p>
                                    <strong>Resale Tax Id:</strong> {user[0].resaleTaxId}
                                </p>
                                <p>
                                    <strong>Customer Status:</strong> {user[0].isApproved}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerPdf