import { combineReducers } from 'redux';
import Auth from './authReducers';
import Product from './productReducers';
import Category from './categoryReducers';
import Tag from './tagReducers';
import loadingReducer from './loadingReducer';
import Order from './orderReducers';
import Tier from './tierReducers';
import SubCategory from './subCategoryReducers';
import Brand from './brandReducers';
import Carrier from './carrierReducers';
import DropshipFee from './dropshipFeeReducers';
import Banner from './bannerReducers';
import Models from './modelReducer';
import Design from './designReducers';
import Tax from './taxReducers';
import Ad from './adReducers';

const rootReducer = combineReducers({
    Ad,
    Auth,
    Product,
    Category,
    Tag,
    loadingReducer,
    Order,
    Tier,
    SubCategory,
    Brand,
    Carrier,
    DropshipFee,
    Banner,
    Models,
    Design,
    Tax
});

export default rootReducer;