import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;

export const getDropshipFee = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_DROPSHIPFEE_REQUEST" });
            const { data } = await axios.get(`${API_URL}/dropshipfee`);
            dispatch({ type: "GET_DROPSHIPFEE_SUCCESS", dropshipfee: data });
        } catch (error) {
            dispatch({ type: "GET_DROPSHIPFEE_FAILURE", error: error.message });

        }
    };
};

export const addDropshipFee = (dropshipFee) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADD_DROPSHIPFEE_REQUEST" });
            const { data } = await axios.post(`${API_URL}/dropshipfee`, dropshipFee, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "ADD_DROPSHIPFEE_SUCCESS",
                dropshipfee: data.createdDropshipFee,
            });
            toast.success('DropshippFee added successfully!');
        } catch (error) {
            dispatch({ type: "ADD_DROPSHIPFEE_FAILURE", error: error.message });
            toast.error('DropshippFee addition failed!');
        }
    };
};

export const updateDropshipFee = (id, dropshipFee) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_DROPSHIPFEE_REQUEST" });
            const { data } = await axios.put(`${API_URL}/dropshipfee/${id}`, dropshipFee, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({ type: "UPDATE_DROPSHIPFEE_SUCCESS", dropshipfee: data });
            toast.success('DropshipFee updated successfully!');
        } catch (error) {
            dispatch({ type: "UPDATE_DROPSHIPFEE_FAILURE", error: error.message });
            toast.error('DropshipFee update failed!');
        }
    };
};

export function setRedirecting() {
    return async dispatch => {
        dispatch({ type: 'SET_REDIRECTING' });
    }
}
