import { React, useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PulseLoader from '../components/PulseLoader';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Papa from 'papaparse';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const { deleteProduct, setRedirecting } = require('../actions/productActions');

const UnpublishedProducts = () => {
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const pro = useSelector(state => state.Product.products);
    const Loading = useSelector(state => state.Product.loading);
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState(searchParams.get("query") || "");
    const [filterProduct, setFilterProduct] = useState([])

    useEffect(() => {
        setProducts(pro.filter(product => product.status === 'Unpublished'));
        // eslint-disable-next-line;
    }, [pro])

    const handleChange = (e) => {
        setQuery(e.target.value);
        setSearchParams({ query: e.target.value });
    }

    dispatch(setRedirecting());
    useEffect(() => {
        if (query) {
            const newProducts = products.filter((product) => {
                return product.barCode.toLowerCase().includes(query.toLowerCase());
            });
            setFilterProduct(newProducts);
        } else {
            navigate('/products/unpublish');
            setFilterProduct(products);
        }

        // eslint-disable-next-line
    }, [query, products]);


    const handleDeleteProduct = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteProduct(id));
        }
    }

    const csvData = products.map(product => {
        return {
            Id: product.id,
            Name: product.name,
            Category: product.category ? product.category.name : 'Uncategorized',
            "Sub Category": product?.subCategory?.name,
            "SKU": product.skuNumber,
            "Bar Code": product.barCode,
            "Consumer Price": product.price,
            "Retail Price": product.retailPrice,
            "Dropshipper Price": product.dropshipperPrice,
            Stock: product.stock,
            "Best Seller": product.bestSeller,
            "Brand": product.brand ? product.brand.name : "All",
            "Model": product.model ? product.model.name : "All",
            "Design": product.design ? product.design.name : "All",
            "Carrier": product.carrier ? product.carrier.name : "All",
            "Weight": product.weight,
            "Status": product.status
        }
    })

    const csv = Papa.unparse(csvData);
    const csvDownload = () => {
        const element = document.createElement("a");
        const file = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        element.href = URL.createObjectURL(file);
        element.download = "products.csv";
        document.body.appendChild(element);
        element.click();
    }


    const selectOptions = [
        { value: 'Draft', label: 'Draft' },
        { value: 'Published', label: 'Published' },
        { value: 'Unpublished', label: 'Unpublished' }
    ]

    const hanldeStatus = (e, id) => {
        e.preventDefault();
        axios.put(`${process.env.REACT_APP_API_HOST}/products/update-status/${id}`, { status: e.target.value }, {

            headers: {
                "x-auth-token": `${localStorage.getItem("xat")}`,
            },
        }).then(res => {
            toast.success('Product status updated!');

        }).catch(err => {
            toast.error('Something went wrong!');
        }
        )
    }

    const columns = [

        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Action',
            cell: row => <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu">
                    <Link className="dropdown-item" to={{
                        pathname: `/products/edit-product/${row.id}`
                    }}><i className="bx bx-edit-alt me-1"></i>Edit</Link>
                    <div className="dropdown-item" onClick={() => { handleDeleteProduct(row.prodId) }} ><i className="bx bx-trash me-1"></i>Delete</div>
                </div>
            </div>,
            width: '100px'
        },
        {
            name: 'Bar Code',
            selector: row => row.barcode,
            sortable: true,
            width: '120px'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Status',
            selector: row => <select onChange={(e) => { hanldeStatus(e, row.prodId) }} >
                {selectOptions.map((option, index) => {
                    return <option key={index} selected={
                        option.value === row.status ? true : false
                    } value={option.value}>{option.label}</option>
                })}
            </select>,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Price',
            selector: row => row.price,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Stock',
            selector: row => row.stock,
            sortable: true,
            width: '100px'
        },

    ];

    const data = filterProduct.map(product => {
        return {
            prodId: product._id,
            id: product.id,
            name: product.name,
            category: product.category ? product.category.name : 'Uncategorized',
            status: product.status,
            price: product.price,
            stock: product.stock,
            barcode: product.barCode,
        }
    })

    return (
        <>
            <div className="content-wrapper container">
                {
                    Loading ? (<PulseLoader />) : (
                        <div className="row p-5">
                            <div className="card">
                                <div className='row'>
                                    <div className="col-md-3">
                                        <h5 className="card-header">All Unpublished Items</h5>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="text" name='search' onChange={handleChange} value={query} className='form-control my-3' placeholder='Search Product By Bar Code' />
                                    </div>
                                    <div className="col-md-5 text-end d-flex justify-content-end ">
                                        <div className='mx-2'>
                                            <button className="btn btn-outline-primary my-3" onClick={csvDownload}>
                                                <span className="tf-icons bx bxs-file-export"></span>&nbsp; Export
                                            </button>
                                        </div>
                                        <div className='mx-2'>
                                            <Link to='/products/import-product' className="btn btn-outline-primary my-3">
                                                <span className="tf-icons bx bxs-file-import"></span>&nbsp; Import
                                            </Link>
                                        </div>
                                        <div className='mx-2'>
                                            <Link to='/products/add-unpublished-product' className="btn btn-outline-primary my-3">
                                                <span className="tf-icons bx bx-plus-circle"></span>&nbsp; Add
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="table-responsive text-nowrap">
                                    <DataTable columns={columns} data={data} defaultSortAsc='true' pagination responsive='true' paginationPerPage={50} />
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default UnpublishedProducts