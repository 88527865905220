import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST


export const getModels = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_MODELS_REQUEST" });
            const { data } = await axios.get(`${API_URL}/model/admin`);
            dispatch({ type: "GET_MODELS_SUCCESS", models: data });
        } catch (error) {
            dispatch({ type: "GET_MODELS_FAILURE", error: error.message });
        }
    };
}

export const addModel = (subcategory) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADD_MODEL_REQUEST" });
            const { data } = await axios.post(`${API_URL}/model`, subcategory, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "ADD_MODEL_SUCCESS",
                model: data.createdModel,
            });
            toast.success('Model added successfully!');
        } catch (error) {
            dispatch({ type: "ADD_MODEL_FAILURE", error: error.message });
        }
    };
}

export const deleteModel = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "DELETE_MODEL_REQUEST" });
            await axios.delete(`${API_URL}/model/${id}`, {
                headers: { "x-auth-token": `${localStorage.getItem("token")}` },
            });
            dispatch({ type: "DELETE_MODEL_SUCCESS", id });
            toast.success('Model deleted successfully!');
        } catch (error) {
            dispatch({ type: "DELETE_MODEL_FAILURE", error: error.message });
        }
    };
}

export const updateModel = (id, subcategory) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_MODEL_REQUEST" });
            const { data } = await axios.put(`${API_URL}/model/${id}`, subcategory, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            toast.success('Model Updated successfully!');
            dispatch({ type: "UPDATE_MODEL_SUCCESS", model: data });
        } catch (error) {
            dispatch({ type: "UPDATE_MODEL_FAILURE", error: error.message });
        }
    };
}

export const setModelStatus = (id, model) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_MODEL_STATUS_REQUEST" });
            dispatch({ type: "UPDATE_MODEL_STATUS_SUCCESS", id, model });
        } catch (error) {
            console.log(error)
            dispatch({ type: "UPDATE_MODEL_STATUS_FAILURE", error: error.message });
        }
    };
}