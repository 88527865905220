import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;


export const getTaxes = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_TAXES_REQUEST" });
            const { data } = await axios.get(`${API_URL}/tax`);
            dispatch({ type: "GET_TAXES_SUCCESS", taxes: data });
        } catch (error) {
            dispatch({ type: "GET_TAXES_FAILURE", error: error.message });

        }
    };
}

export const addTax = (tax) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADD_TAX_REQUEST" });
            const { data } = await axios.post(`${API_URL}/tax`, tax, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "ADD_TAX_SUCCESS",
                tax: data,
            });
            toast.success('Tax added successfully!');
        } catch (error) {
            dispatch({ type: "ADD_TAX_FAILURE", error: error.message });
            toast.error('Tax addition failed!');
        }
    };
}

export const deleteTax = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "DELETE_TAX_REQUEST" });
            await axios.delete(`${API_URL}/tax/${id}`, {
                headers: { "x-auth-token": `${localStorage.getItem("token")}` },
            });
            dispatch({ type: "DELETE_TAX_SUCCESS", id });
            toast.success('Tax deleted successfully!');
        } catch (error) {
            dispatch({ type: "DELETE_TAX_FAILURE", error: error.message });
            toast.error('Tax deletion failed!');
        }
    };
}

export const updateTax = (id, tax) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_TAX_REQUEST" });
            const { data } = await axios.put(`${API_URL}/tax/${id}`, { tax }, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });

            dispatch({ type: "UPDATE_TAX_SUCCESS", tax: data });
            toast.success('Tax updated successfully!');
        } catch (error) {
            dispatch({ type: "UPDATE_TAX_FAILURE", error: error.message });
            toast.error('Tax updation failed!');
        }
    };
}


export function setRedirecting() {
    return async dispatch => {
        dispatch({ type: 'SET_REDIRECTING' });
    }
}