import { React } from "react";
import { Toaster } from 'react-hot-toast';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SidebarLayout from './components/Sidebar/SidebarLayout';
import Login from './views/Login';
import PageNotFound from './views/PageNotFound';
import Product from './views/Product';
import Otp from './components/OTP/Otp';
import Category from './views/Category';
import CategoryForm from './views/CategoryForm';
import Dashboard from './views/Dashboard';
import Tier from './views/Tier';
import Order from './views/Order';
import OrderDeatil from './views/OrderDeatil';
import Customers from "./views/Customers";
import TierForm from "./views/TierForm";
import CustomerForm from "./views/CustomerForm";
import SubCategory from "./views/SubCategory";
import SubCategoryForm from "./views/SubCategoryForm";
import CustomersRequest from "./views/CustomersRequest";
import Tag from "./views/Tag";
import TagForm from "./views/TagForm";
import ProductForm from "./views/ProductForm";
import CustomerPdf from "./views/CustomerPdf";
import Brand from "./views/Brand";
import BrandForm from "./views/BrandForm";
import Carrier from "./views/Carrier";
import CarrierForm from "./views/CarrierForm";
import DropshipFee from "./views/DropshipFee";
import Banner from "./views/Banner";
import Model from "./views/Model";
import ModelForm from "./views/ModelForm";
import Design from "./views/Design";
import DesignForm from "./views/DesignForm";
import BannerForm from "./views/BannerForm";
import ProductImport from "./views/ProductImport";
import TaxForm from "./views/TaxForm";
import DraftProducts from "./views/DraftProducts";
import PublishedProducts from "./views/PublishedProducts";
import UnpublishedProducts from "./views/UnpublishedProducts";
import TaxNew from "./views/TaxNew";
import OrderReport from "./reports/OrderReport";
import Ad from "./views/Ad";
import AdForm from "./views/AdForm";
import AdminUser from "./views/AdminUser";
import AdminUserForm from "./views/AdminUserForm";
import RetailerOrderListing from "./views/retailer/RetailerOrderListing";
import RetailerOrderDetails from "./views/retailer/RetailerOrderDetails"

function App() {
  return (
    <>
      <Router>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <Routes>
              <Route element={<SidebarLayout />} >
                <Route path="/" element={<ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>} />
                <Route path="/user" element={<ProtectedRoute>
                  <AdminUser />
                </ProtectedRoute>} />
                <Route path="/user/:id" element={<ProtectedRoute>
                  <AdminUserForm />
                </ProtectedRoute>} />

                <Route path="/categories" element={<ProtectedRoute>
                  <Category />
                </ProtectedRoute>} />

                <Route path="/categories/add-category" element={<ProtectedRoute>
                  <CategoryForm FormName="Add Category" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/categories/edit-category/:id" element={<ProtectedRoute>
                  <CategoryForm FormName="Edit Category" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/sub-categories" element={<ProtectedRoute>
                  <SubCategory />
                </ProtectedRoute>} />

                <Route path="/sub-categories/add-subcategory" element={<ProtectedRoute>
                  <SubCategoryForm FormName="Add Sub Category" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/sub-categories/edit-subcategory/:id" element={<ProtectedRoute>
                  <SubCategoryForm FormName="Edit Sub Category" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/models" element={<ProtectedRoute>
                  <Model />
                </ProtectedRoute>} />

                <Route path="/model/add-model" element={<ProtectedRoute>
                  <ModelForm FormName="Add Model" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/model/edit-model/:id" element={<ProtectedRoute>
                  <ModelForm FormName="Edit Model" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/banners" element={<ProtectedRoute>
                  <Banner />
                </ProtectedRoute>} />

                <Route path="/ads-spots" element={<ProtectedRoute>
                  <Ad />
                </ProtectedRoute>} />

                <Route path="/ads-spots/add-ad" element={<ProtectedRoute>
                  <AdForm FormName="Add Ad Spot" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/ads-spots/edit-ad/:id" element={<ProtectedRoute>
                  <AdForm FormName="Edit Ad Spot" Editable="true" />
                </ProtectedRoute>} />


                <Route path="/banners/add-banner" element={<ProtectedRoute>
                  <BannerForm FormName="Add Banner" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/banners/edit-banner/:id" element={<ProtectedRoute>
                  <BannerForm FormName="Edit Banner" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/brands" element={<ProtectedRoute>
                  <Brand />
                </ProtectedRoute>} />

                <Route path="/brands/add-brand" element={<ProtectedRoute>
                  <BrandForm FormName="Add Brand" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/brands/edit-brand/:id" element={<ProtectedRoute>
                  <BrandForm FormName="Edit Brand" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/designs" element={<ProtectedRoute>
                  <Design />
                </ProtectedRoute>} />

                <Route path="/designs/add-design" element={<ProtectedRoute>
                  <DesignForm FormName="Add Design" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/designs/edit-design/:id" element={<ProtectedRoute>
                  <DesignForm FormName="Edit Design" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/carriers" element={<ProtectedRoute>
                  <Carrier />
                </ProtectedRoute>} />

                <Route path="/carriers/add-carrier" element={<ProtectedRoute>
                  <CarrierForm FormName="Add Carrier" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/carriers/edit-carrier/:id" element={<ProtectedRoute>
                  <CarrierForm FormName="Edit Carrier" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/tier" element={<ProtectedRoute>
                  <Tier />
                </ProtectedRoute>} />

                <Route path="/tier/add-tier" element={<ProtectedRoute>
                  <TierForm FormName="Add Tier" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/tier/edit-tier/:id" element={<ProtectedRoute>
                  <TierForm FormName="Edit Tier" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/tag" element={<ProtectedRoute>
                  <Tag />
                </ProtectedRoute>} />

                <Route path="/tag/add-tag" element={<ProtectedRoute>
                  <TagForm FormName="Add Tag" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/tag/edit-tag/:id" element={<ProtectedRoute>
                  <TagForm FormName="Edit Tag" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/products" element={<ProtectedRoute>
                  <Product />
                </ProtectedRoute>} />

                <Route path="/products/draft" element={<ProtectedRoute>
                  <DraftProducts />
                </ProtectedRoute>} />
                <Route path="/products/publish" element={<ProtectedRoute>
                  <PublishedProducts />
                </ProtectedRoute>} />
                <Route path="/products/unpublish" element={<ProtectedRoute>
                  <UnpublishedProducts />
                </ProtectedRoute>} />

                <Route path="/orders" element={<ProtectedRoute>
                  <Order />
                </ProtectedRoute>} />
                <Route path="/retailer-orders" element={<ProtectedRoute>
                  <RetailerOrderListing />
                </ProtectedRoute>} />

                <Route path="/sale-report" element={<ProtectedRoute>
                  <OrderReport />
                </ProtectedRoute>} />

                <Route path="/order-details/:id" element={<ProtectedRoute>
                  <OrderDeatil />
                </ProtectedRoute>} />
                <Route path="/retailer-order-details/:id" element={<ProtectedRoute>
                  <RetailerOrderDetails />
                </ProtectedRoute>} />

                <Route path="/products/add-product" element={<ProtectedRoute>
                  <ProductForm FormName="Add Product" Editable="false" status={"Published"} />
                </ProtectedRoute>} />

                <Route path="/products/add-draft-product" element={<ProtectedRoute>
                  <ProductForm FormName="Add Draft Product" Editable="false" status={"Draft"} />
                </ProtectedRoute>} />

                <Route path="/products/add-unpublished-product" element={<ProtectedRoute>
                  <ProductForm FormName="Add Unpublish Product" Editable="false" status={"Unpublished"} />
                </ProtectedRoute>} />

                <Route path="/products/edit-product/:id" element={<ProtectedRoute>
                  <ProductForm FormName="Edit Product" Editable="true" />
                </ProtectedRoute>} />


                <Route path="/products/import-product" element={<ProtectedRoute>
                  <ProductImport />
                </ProtectedRoute>} />

                <Route path="/customers" element={<ProtectedRoute>
                  <Customers type={"Customers"} />
                </ProtectedRoute>} />

                <Route path="/customers/approve-cusotmer/:id" element={<ProtectedRoute>
                  <CustomerForm type={"Customers"} />
                </ProtectedRoute>} />

                <Route path="/customer/pending-requests" element={<ProtectedRoute>
                  <CustomersRequest type={"Customers"} filterType={"Pending"} />
                </ProtectedRoute>} />

                <Route path="/customer/rejected-requests" element={<ProtectedRoute>
                  <CustomersRequest type={"Customers"} filterType={"Rejected"} />
                </ProtectedRoute>} />

                <Route path="/customer/blocked-requests" element={<ProtectedRoute>
                  <CustomersRequest type={"Customers"} filterType={"Blocked"} />
                </ProtectedRoute>} />

                <Route path="/customer/downloadinfo/:id" element={<ProtectedRoute>
                  <CustomerPdf />
                </ProtectedRoute>} />

                <Route path="/dropshipfee" element={<ProtectedRoute>
                  <DropshipFee FormName="Dropship Fees" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/tax" element={<ProtectedRoute>
                  <TaxNew />
                </ProtectedRoute>} />

                <Route path="/taxes/add-tax" element={<ProtectedRoute>
                  <TaxForm FormName="Add Tax" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/admin-user" element={<ProtectedRoute>
                  <AdminUser />
                </ProtectedRoute>} />

                <Route path="/admin-user/add" element={<ProtectedRoute>
                  <AdminUserForm FormName="Add Admin User" Editable="false" />
                </ProtectedRoute>} />

                <Route path="/admin-user/:id" element={<ProtectedRoute>
                  <AdminUserForm FormName="Edit Admin User" Editable="true" />
                </ProtectedRoute>} />

                <Route path="/taxes/edit-tax/:id" element={<ProtectedRoute>
                  <TaxForm FormName="Edit Tax" Editable="true" />
                </ProtectedRoute>} />


              </Route>
              <Route path="/login" element={<Login />} />
              <Route path='/verification/email' element={<Otp />} />
              <Route path="*" element={<PageNotFound />} />


            </Routes>
          </div>
          <div className="layout-overlay layout-menu-toggle"></div>
        </div>
        <Toaster
          position="top-center"
          reverseOrder={true}
        />
      </Router>
    </>
  );
}

export default App;

