import { React } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PulseLoader from '../components/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-hot-toast';
const { deleteModel, setModelStatus } = require('../actions/modelActions');

const Model = () => {

    const dispatch = useDispatch();
    const { models, loading } = useSelector(state => state.Models);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteModel(id));
        }
    }

    const hanldeStatus = (e, id) => {
        const status = e.target.value;
        axios.put(`${process.env.REACT_APP_API_HOST}/model/status/${id}`, { status }, {
            headers: {
                "x-auth-token": `${localStorage.getItem("token")}`,
            },
        }).then(res => {
            dispatch(setModelStatus(id, res.data));
            toast.success('Status updated successfully!');
        }
        ).catch(err => {
            toast.error('Something went wrong!');
        })
    }

    const selectOptions = [
        {
            label: 'Active',
            value: 'active'
        },
        {
            label: 'Inactive',
            value: 'inactive'
        }
    ]


    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Action',
            cell: row => <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu">
                    <Link className="dropdown-item" to={{
                        pathname: `/model/edit-model/${row.id}`
                    }}><i className="bx bx-edit-alt me-1"></i> Edit</Link>
                    <div className="dropdown-item" onClick={() => { handleDelete(row._id) }} ><i className="bx bx-trash me-1"></i> Delete</div>
                </div>
            </div>,
            width: '100px',
        },
        {
            name: 'Status',
            selector: row => <select onChange={(e) => { hanldeStatus(e, row._id) }} >
                {selectOptions.map((option, index) => {
                    return <option key={index} selected={
                        option.value === row.status ? true : false
                    } value={option.value}>{option.label}</option>
                })}
            </select>,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Slug',
            selector: row => row.slug,
        },
        {
            name: 'Brand',
            selector: row => row.brand,
        },

    ];


    const data = models.map(model => {
        return {
            _id: model._id,
            id: model.id,
            name: model.name,
            slug: model.slug,
            brand: model.brand?.name,
            status: model.status,
        }
    })


    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    loading ? (<PulseLoader />) : (
                        <div className="row p-5">
                            <div className="card">
                                <div className='d-flex justify-content-between'>
                                    <h5 className="card-header">All Models</h5>
                                    <div>
                                        <Link to='/model/add-model' className="btn btn-outline-primary my-3">
                                            <span className="tf-icons bx bx-plus-circle"></span>&nbsp; Add Model
                                        </Link>
                                    </div>
                                </div>
                                <div className="table-responsive text-nowrap">
                                    <DataTable columns={columns} data={data} defaultSortAsc='true' pagination responsive='true' />
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default Model