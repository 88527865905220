import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;

export const getCarriers = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_CARRIERS_REQUEST" });
            const { data } = await axios.get(`${API_URL}/carrier`);
            dispatch({ type: "GET_CARRIERS_SUCCESS", carriers: data });
        } catch (error) {
            dispatch({ type: "GET_CARRIERS_FAILURE", error: error.message });

        }
    };
};

export const addCarrier = (carrier) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADD_CARRIER_REQUEST" });
            const { data } = await axios.post(`${API_URL}/carrier`, carrier, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "ADD_CARRIER_SUCCESS",
                carrier: data.createdCarrier,
            });
            toast.success('Carrier added successfully!');
        } catch (error) {
            dispatch({ type: "ADD_CARRIER_FAILURE", error: error.message });
            toast.error('Carrier addition failed!');
        }
    };
};

export const deleteCarrier = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "DELETE_CARRIER_REQUEST" });
            await axios.delete(`${API_URL}/carrier/${id}`, {
                headers: { "x-auth-token": `${localStorage.getItem("token")}` },
            });
            dispatch({ type: "DELETE_CARRIER_SUCCESS", id });
            toast.success('Carrier deleted successfully!');
        } catch (error) {
            dispatch({ type: "DELETE_CARRIER_FAILURE", error: error.message });
            toast.error('Carrier deletion failed!');
        }
    };
};

export const updateCarrier = (id, carrier) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_CARRIER_REQUEST" });
            const { data } = await axios.put(`${API_URL}/carrier/${id}`, carrier, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({ type: "UPDATE_CARRIER_SUCCESS", carrier: data });
            toast.success('Carrier updated successfully!');
        } catch (error) {
            dispatch({ type: "UPDATE_CARRIER_FAILURE", error: error.message });
            toast.error('Carrier update failed!');
        }
    };
};

export const loadCarriers = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "LOAD_CARRIERS_REQUEST" });
            dispatch({ type: "LOAD_CARRIERS_SUCCESS", carriers: data });
        } catch (error) {
            dispatch({ type: "LOAD_CARRIERS_FAILURE", error: error.message });
        }
    };
};


export function setRedirecting() {
    return async dispatch => {
        dispatch({ type: 'SET_REDIRECTING' });
    }
}
