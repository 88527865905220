const ShippingDetails=({order,updateOrderStatusDispatch})=>{
  
 return (<div className="row">
    <div className="col-md-6">
      <div className="card mb-3">
        <div className="card-body">
          <p>
            <b>Name : </b> {order.shippingInfo.name}
          </p>
          <p>
            <b>Email : </b> {order.shippingInfo.email}
          </p>
          <p>
            <b>Phone : </b> {order.shippingInfo.phone}
          </p>
          <p>
            <b>City : </b> {order.shippingInfo.city}
          </p>
          <p>
            <b>Country : </b> {order.shippingInfo.country}
          </p>
          <p>
            <b>Zip Code : </b> {order.shippingInfo.postalCode}
          </p>
          <p>
            <b>Address : </b> {order.shippingInfo.address}
          </p>
          <p>
            <b>Order Note : </b> {order.orderNote}
          </p>
          <p>

          </p>
        </div>
      </div>
    </div>
    {/* <div className="col-md-6">
      <div className="card mb-3">
        <h4 className="card-header">Shipping Actions</h4>
        <div className="card-body">
          <div className="d-flex gap-3">
            <button className="btn btn-outline-success" >Create Shipping Label</button>
            <button className={order.status === "Delivered" ? "d-none" : "btn btn-outline-primary"} onClick={() => updateOrderStatusDispatch("Shipped")}>
              {order.status !== "Shipped" ? ("Mark as Shipped") : (order.status === "Cancelled" ? orderStatus : "Shipped")}
            </button>
            <button className={order.status === "Delivered" ? "d-none" : "btn btn-outline-danger"} onClick={() => updateOrderStatusDispatch("Cancelled")} disabled={order.status === "Delivered" ? true : false}>
              {order.status !== "Cancelled" ? ("Cancel Order") : (" Cancelled")}
            </button>
          </div>
        </div>
      </div>
    </div> */}
  </div>)
}

export default ShippingDetails;