const order = {
  orders: [],
  retailer_orders: [],
  loading: false,
  error: null,
  report: [],
};

const Order = (state = order, action) => {
  switch (action.type) {
    case "ORDERS_GET_ALL_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "RETAILER_ORDERS_GET_ALL_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ORDERS_GET_ALL_SUCCESS":
      return {
        ...state,
        loading: false,
        orders: action.orders,
      };
    case "ORDERS_GET_ALL_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "RETAILER_ORDERS_GET_ALL_SUCCESS":
      return {
        ...state,
        loading: false,
        retailer_orders: action.orders,
      };
    case "RETAILER_ORDERS_GET_ALL_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "LOAD_ORDERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "LOAD_ORDERS_SUCCESS":
      return {
        ...state,
        loading: false,
        products: action.products,
      };
    case "LOAD_ORDERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "UPDATE_ORDER_STATUS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_ORDER_STATUS_SUCCESS":
      return {
        ...state,
        loading: false,
        orders: state.orders.map((order) =>
          order.id === action.order.id ? action.order : order
        ),
      };
    case "UPDATE_ORDER_STATUS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "UPDATE_RETAILER_ORDER_STATUS_SUCCESS":
      console.log(action.order)
      return {
        ...state,
        loading: false,
        retailer_orders: state.retailer_orders.map((order) =>
          order.id === action.order.id ? {...order,status:action.order.status} : order
        ),
      };
    case "UPDATE_RETAILER_ORDER_STATUS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "ORDERS_REPORT_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ORDERS_REPORT_SUCCESS":
      return {
        ...state,
        loading: false,
        report: action.report,
      };
    case "ORDERS_REPORT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Order;
