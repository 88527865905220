const dropshipfee = {
    dropshipfee: [],
    message: "",
    redirect: false,
    loading: false,
    error: null,
};

const DropshipFee = (state = dropshipfee, action) => {
    switch (action.type) {
        case "GET_DROPSHIPFEE_REQUEST":
            return {
                ...state,
            };
        case "GET_DROPSHIPFEE_SUCCESS":
            return {
                ...state,
                loading: false,
                dropshipfee: action.dropshipfee,
            };
        case "GET_DROPSHIPFEE_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "ADD_DROPSHIPFEE_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "ADD_DROPSHIPFEE_SUCCESS":
            return {
                ...state,
                loading: false,
                dropshipfees: [...state.dropshipfee, action.dropshipfee],
                redirect: true,
            };
        case "ADD_DROPSHIPFEE_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "UPDATE_DROPSHIPFEE_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "UPDATE_DROPSHIPFEE_SUCCESS":
            return {
                ...state,
                loading: false,
                dropshipfee: state.dropshipfee.map((dropshipfee) =>
                    dropshipfee.id === action.dropshipfee.id ? action.dropshipfee : dropshipfee
                ),
                redirect: true,
            };
        case "UPDATE_DROPSHIPFEE_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "SET_REDIRECTING":
            return {
                ...state,
                redirect: false,
            };

        default:
            return state;
    }
};

export default DropshipFee;
