import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { formatPhoneNumber } from '../utils/other';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const { userUpdate, addAdminUser, setRedirect, userUpdateAdmin } = require('../actions/authActions');


const AdminUserForm = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { FormName, Editable } = props;
    const { adminList, redirect, loading } = useSelector(state => state.Auth);
    const [adminUser, setAdminUser] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
    });

    useEffect(() => {
        if (id) {
            // eslint-disable-next-line
            const user = adminList.find(user => user._id == id);
            if (user) {
                setAdminUser({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    email: user.email,
                    password: '',
                });
            }
        }

        // eslint-disable-next-line
    }, [id, adminList]);

    // dispatch(setRedirect())

    useEffect(() => {
        if (redirect) {
            navigate('/admin-user');
            dispatch(setRedirect())
        }
        // eslint-disable-next-line
    }, [redirect]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            setAdminUser({ ...adminUser, [name]: formatPhoneNumber(value) });
            return;
        } else {
            setAdminUser({ ...adminUser, [name]: value });
        }
    };

    const registerAdminUser = (e) => {
        e.preventDefault();

        if (adminUser.firstName === '' || adminUser.lastName === '' || adminUser.email === '' || adminUser.password === '') return toast.error('Please fill all the fields');

        dispatch(addAdminUser(adminUser));
    }

    const updateAdminUser = (e) => {
        e.preventDefault();

        if (adminUser.firstName === '' || adminUser.lastName === '' || adminUser.email === '') return toast.error('Please fill all the fields');

        dispatch(userUpdateAdmin(id, adminUser));
    }

    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    loading ? (<PulseLoader />) : (
                        <div className="row p-5 justify-content-center">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">{FormName}</h5>
                                        <div>
                                            <Link to='/admin-users/' className="btn btn-outline-primary my-1">
                                                <span className="tf-icons bx bx-left-arrow-alt"></span>&nbsp; Back to List
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form autoComplete="off">
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <InputField
                                                            label="First Name"
                                                            id="firstName"
                                                            name='firstName'
                                                            value={adminUser.firstName}
                                                            onChange={handleInputChange}
                                                            required={true} type='text'
                                                            placeholder="First Name here..."
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <InputField label="Last Name" id="lastName" name='lastName' value={adminUser.lastName} onChange={handleInputChange} required={true} type='text' placeholder="Last Name here..." autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3">
                                                        <InputField label="Phone" id="phone" name='phone' value={adminUser.phone} onChange={handleInputChange} required={true} type='text' placeholder="Phone here..." autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <InputField label="Email" id="email" name='email' value={adminUser.email} onChange={handleInputChange} required={true} type='email' placeholder="Email here..." autoComplete="off" readonly={id ? true : false} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <InputField label={id ? "Paasowrd (Leave blank to leave unchanged)" : "Password"} id="password" name='password' value={adminUser.password} onChange={handleInputChange} required={id ? false : true} type='password' placeholder="Password here..." autoComplete="new-password" />
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                Editable === 'true' ? <button type="submit" onClick={updateAdminUser} className="btn btn-primary">
                                                    Update
                                                </button> : <button type="submit" onClick={registerAdminUser} className="btn btn-primary">
                                                    Save
                                                </button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default AdminUserForm;