import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
// import ImageField from '../components/InputFields/ImageField';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import { toast } from 'react-hot-toast';
const { addSubCategory, updateSubCategory } = require('../actions/subCategoryActions');

const SubCategoryForm = (props) => {
  const { id } = useParams();
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const { FormName, Editable } = props;
  const { Loading } = useSelector(state => state.Category);
  const { categories } = useSelector(state => state.Category);
  const { subCategories } = useSelector(state => state.SubCategory);
  const [subcategory, setSubCat] = useState({});
  const [SubCategory, setSubCategory] = useState({
    name: "",
    photo: [],
    category: ""
  });


  const selectOptions = categories.map((category) => {
    return { value: category._id, label: category.name };
  });

  const handleCategory = (e) => {
    setSubCategory({
      ...SubCategory,
      category: e.value,
    });
  };

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
      const cat = subCategories.find(category => category.id == id);
      if (cat) {
        setSubCat(cat);
        setSubCategory({
          name: cat.name,
          category: cat.category,
          photo: cat.photo
        });
      }
    }

  }, [id, subCategories]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubCategory({ ...SubCategory, [name]: value });
  };

  // const handleFileChange = (e) => {
  //   const photo = e.target.files[0];
  //   setSubCategory({ ...SubCategory, photo });
  // }

  const handleAddSubCategory = (e) => {
    e.preventDefault();
    if (SubCategory.name === '') {
      toast.error('Please enter sub category name');
      return;
    } else if (SubCategory.category === '') {
      toast.error('Please select category');
      return;
    } else if (SubCategory.name !== '' && SubCategory.category !== '') {

      dispatch(addSubCategory(SubCategory));
      redirect('/sub-categories');
    }
  }

  const handleUpdateSubCategory = (e) => {
    e.preventDefault();

    dispatch(updateSubCategory(subcategory._id, SubCategory));
    redirect('/sub-categories');
  }

  return (
    <>
      <div className="content-wrapper container-fluid">
        {
          Loading ? (<PulseLoader />) : (
            <div className="row p-4 justify-content-center">
              <div className="col-md-5">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">{FormName}</h5>
                  </div>
                  <div className="card-body">
                    <form  >
                      <div className='row'>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <InputField label="Name" name='name' value={SubCategory.name} onChange={handleInputChange} required={true} type='text' placeholder="Name here..." />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label required"
                              htmlFor="basic-default-fullname"
                            >
                              Category
                            </label>
                            <Select
                              options={selectOptions}
                              onChange={handleCategory}
                              value={
                                SubCategory.category === null
                                  ? ""
                                  : selectOptions.find((option) => {
                                    return (
                                      option.value === SubCategory.category._id
                                    );
                                  })
                              }
                            />
                          </div>
                        </div>

                      </div>

                      {
                        Editable === 'true' ? <button type="submit" onClick={handleUpdateSubCategory} className="btn btn-primary">
                          Update Category
                        </button> : <button type="submit" onClick={handleAddSubCategory} className="btn btn-primary" >
                          Save
                        </button>
                      }

                    </form>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className="content-backdrop fade"></div>
      </div>
    </>
  )
}

export default SubCategoryForm