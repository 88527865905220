import { React, useState } from 'react'
import Select from "react-select";
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import '../components/InputFields/switch.css'
const { approveUser } = require('../actions/authActions');

const CustomerForm = () => {
  const { id } = useParams();
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const { loading, userList } = useSelector(state => state.Auth);
  const { tiers } = useSelector(state => state.Tier);
  var updatedUser = userList.find(user => user._id === id);
  const [User, setUser] = useState({
    id: updatedUser._id,
    firstName: updatedUser.firstName,
    lastName: updatedUser.lastName,
    businessType: updatedUser.businessType,
    companyName: updatedUser.companyName,
    regState: updatedUser.regState,
    tradeName: updatedUser.tradeName,
    inBusinessSince: updatedUser.inBusinessSince,
    physicalAddress: updatedUser.physicalAddress,
    shopNumber: updatedUser.shopNumber,
    city: updatedUser.city,
    state: updatedUser.state,
    zipCode: updatedUser.zipCode,
    telBusiness: updatedUser.telBusiness,
    fax: updatedUser.fax,
    email: updatedUser.email,
    fedTaxId: updatedUser.fedTaxId,
    resaleTaxId: updatedUser.resaleTaxId,
    type: updatedUser.type,
    tier: updatedUser.tier,
    isApproved: updatedUser.isApproved,
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...User, [name]: value });
  };

  const handleEditUser = (e) => {
    e.preventDefault();
    dispatch(approveUser(User.id, User));
    redirect('/customers');
  }

  const selectOptions = tiers.map((tier) => {
    return { value: tier._id, label: tier.tierName };
  });

  const selectApprove = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Blocked', label: 'Blocked' },
  ]

  const handleTier = (e) => {
    setUser({
      ...User,
      tier: e.value,
    });
  };

  const handleApprove = (e) => {
    setUser({
      ...User,
      isApproved: e.value,
    });
  };


  return (
    <>
      <div className="content-wrapper container-fluid">
        {loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5 justify-content-center">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Update Customer</h5>
                </div>
                <div className="card-body">
                  <form encType="multipart/form-data" method="POST">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="First Name"
                            name="firstName"
                            value={User.firstName}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="First Name here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Last Name"
                            name="lastName"
                            value={User.lastName}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Last Name here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Business Type"
                            name="businessType"
                            value={User.businessType}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="User Type here... (Ex: Customer)"
                            readonly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Company Name"
                            name="companyName"
                            value={User.companyName}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Company Name here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Reg State"
                            name="regState"
                            value={User.regState}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Reg State here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Trade Name "
                            name="tradeName"
                            value={User.tradeName}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Trade Name here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="In Business Since"
                            name="inBusinessSince"
                            value={User.inBusinessSince}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="In Business Since here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Shop/KIOSK/Suite #"
                            name="shopNumber"
                            value={User.shopNumber}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Shop Number here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="City"
                            name="city"
                            value={User.city}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="city here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="State"
                            name="state"
                            value={User.state}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="State here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Zip Code"
                            name="zipCode"
                            value={User.zipCode}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Zip Code here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Tel Business "
                            name="telBusiness"
                            value={User.telBusiness}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Tel Business here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Fax"
                            name="fax"
                            value={User.fax}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Fax here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Email"
                            name="email"
                            value={User.email}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Email here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Fed Tax Id"
                            name="fedTaxId"
                            value={User.fedTaxId}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Fed Tax Id here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Resale Tax Id"
                            name="resaleTaxId"
                            value={User.resaleTaxId}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Resale Tax Id here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="User Type"
                            name="type"
                            value={User.type}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="User Type here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <InputField
                            label="Physical Address"
                            name="physicalAddress"
                            value={User.physicalAddress}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Physical Address here..."
                            readonly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Discount Tier
                          </label>
                          <Select
                            options={selectOptions}
                            onChange={handleTier}
                            value={
                              User.tier === undefined
                                ? ""
                                : selectOptions.find((option) => {
                                  return (
                                    option.value === User.tier._id
                                  );
                                })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Status
                          </label>
                          <Select
                            options={selectApprove}
                            onChange={handleApprove}
                            value={
                              User.isApproved === undefined
                                ? ""
                                : selectApprove.find((option) => {
                                  return (
                                    option.value === User.isApproved
                                  );
                                })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={handleEditUser}
                      className="btn btn-primary"
                    >
                      Update User
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="content-backdrop fade"></div>
      </div>
    </>
  )
}

export default CustomerForm