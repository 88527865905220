import React from 'react'

const InputField = (props) => {
  const { label, name, value, onChange, required, type, placeholder, readonly } = props;

  return (
    <>
      <label className={required === true ? 'form-label required' : 'form-label'} >{label}</label>
      <input type={type} className="form-control" value={value} required={required} onChange={onChange} name={name} placeholder={placeholder} readOnly={readonly || false} onKeyDown={(e) => {
        if (
          e.key === "ArrowUp" ||
          e.key === "ArrowDown"
        ) {
          e.preventDefault();
        }
      }} onWheel={(e) => e.target.blur()} />
    </>
  )
}

export default InputField