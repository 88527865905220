export const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, "");

    if (cleaned.length === 0) {
        return "";
    }

    let formattedPhoneNumber = "";
    if (cleaned.length >= 1) {
        formattedPhoneNumber += `${cleaned.slice(0, 3)}`;
    }
    if (cleaned.length >= 4) {
        formattedPhoneNumber += `-${cleaned.slice(3, 6)}`;
    }
    if (cleaned.length >= 7) {
        formattedPhoneNumber += `-${cleaned.slice(6, 10)}`;
    }

    formattedPhoneNumber = formattedPhoneNumber.replace(/--/g, "-");

    return formattedPhoneNumber;
};
