import { React, useState } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PulseLoader from '../components/PulseLoader';

import { useSelector, useDispatch } from 'react-redux';
import { deleteAD } from '../actions/adsAction';



const Ad = () => {
    const dispatch = useDispatch();
    const { ads, loading } = useSelector(state => state.Ad);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteAD(id));
        }
    }

    // eslint-disable-next-line
    const [position, setPosition] = useState([
        {
            option: 'Ad Spot 1',
            value: 'adspot1'
        },
        {
            option: 'Ad Spot 2',
            value: 'adspot2'
        },
        {
            option: 'Ad Spot 3',
            value: 'adspot3'
        },
        {
            option: 'Ad Spot 4',
            value: 'adspot4'
        },
        {
            option: 'Mini Ad',
            value: 'miniad'
        }
    ]);

    const columns = [
        {
            name: 'Id',
            selector: row => row.adId,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Action',
            cell: row => <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu">
                    <Link className="dropdown-item" to={{
                        pathname: `/ads-spots/edit-ad/${row.adId}`
                    }}><i className="bx bx-edit-alt me-1"></i> Edit</Link>
                    <div className="dropdown-item" onClick={() => { handleDelete(row.id) }} ><i className="bx bx-trash me-1"></i> Delete</div>
                </div>
            </div>,
            width: '100px',
        },
        {
            name: 'Photo',
            selector: row => row.photo,
        },
        {
            name: 'Position',
            selector: row => position.filter(pos => pos.value === row.pos)[0].option,
            sortable: true,

        },
        {
            name: 'URL',
            selector: row => row.url,
            sortable: true,
        },

    ];

    const data = ads.map(ad => {
        return {
            adId: ad.id,
            id: ad._id,
            pos: ad.position,
            photo: <img src={ad.photo[0]} alt={ad.name} className="img-fluid" width="100" />,
            url: ad.url,
        }
    })

    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    loading ? (<PulseLoader />) : (
                        <div className="row p-5">
                            <div className="card">
                                <div className='d-flex justify-content-between'>
                                    <h5 className="card-header">All Ads</h5>
                                    <div>
                                        <Link to='/ads-spots/add-ad' className="btn btn-outline-primary my-3">
                                            <span className="tf-icons bx bx-plus-circle"></span>&nbsp; Ad Spot
                                        </Link>
                                    </div>
                                </div>
                                <div className="table-responsive text-nowrap">
                                    <DataTable columns={columns} data={data} defaultSortAsc='true' pagination responsive='true' />
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default Ad;