import axios from 'axios';
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST


export const userLogin = (login) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_LOGIN_REQUEST' });
            const { data } = await axios.post(`${API_URL}/auth/login-admin`, login);
            localStorage.setItem('xat', data.token);
            toast.success('User Logged in successfully!');
            dispatch({ type: 'USER_LOGIN_SUCCESS', user: data });
        } catch (error) {
            toast.error('Invalid Credentials!');
            dispatch({ type: 'USER_LOGIN_FAILURE', error: error.message });
        }
    }
}

export const userLogout = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_LOGOUT_REQUEST' });
            localStorage.clear();
            dispatch({ type: 'USER_LOGOUT_SUCCESS' });
        } catch (error) {
            dispatch({ type: 'USER_LOGOUT_FAILURE', error: error.message });
        }
    }
}

export const userRegister = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_REGISTER_REQUEST' });
            const { data } = await axios.post(`${API_URL}/auth/signup`, user);
            dispatch({ type: 'USER_REGISTER_SUCCESS', user: data });
        } catch (error) {
            dispatch({ type: 'USER_REGISTER_FAILURE', error: error.message });
        }
    }
}

export const userUpdate = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_UPDATE_REQUEST' });
            const { data } = await axios.post(`${API_URL}/auth/update`, user);
            dispatch({ type: 'USER_UPDATE_SUCCESS', user: data });
        } catch (error) {
            dispatch({ type: 'USER_UPDATE_FAILURE', error: error.message });
        }
    }
}

export const userDelete = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_DELETE_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = axios.delete(`${API_URL}/auth/delete/${id}`, config);

            dispatch({ type: 'USER_DELETE_SUCCESS' }, data);
        } catch (error) {
            dispatch({ type: 'USER_DELETE_FAILURE', error: error.message });
        }
    }
}


export const userGetAll = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_GET_ALL_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.get(`${API_URL}/auth/getAll`, config);
            dispatch({ type: 'USER_GET_ALL_SUCCESS', userList: data });
        } catch (error) {
            dispatch({ type: 'USER_GET_ALL_FAILURE', error: error.message });
        }
    }
}

export const userGetAllAdmin = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_GET_ALL_ADMIN_REQUEST' });
            const { data } = await axios.get(`${API_URL}/auth/getAllAdmin`);
            dispatch({ type: 'USER_GET_ALL_ADMIN_SUCCESS', users: data });
        } catch (error) {
            dispatch({ type: 'USER_GET_ALL_ADMIN_FAILURE', error: error.message });
        }
    }
}

export const approveUser = (id, user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_APPROVE_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.put(`${API_URL}/auth/approve/${id}`, user, config);
            dispatch({ type: 'USER_APPROVE_SUCCESS', user: data });
        } catch (error) {
            dispatch({ type: 'USER_APPROVE_FAILURE', error: error.message });
        }
    }
}

export const getAdminUser = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_GET_ALL_ADMIN_REQUEST' });
            const comfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.get(`${API_URL}/auth/get-admin`, comfig);
            dispatch({ type: 'USER_GET_ALL_ADMIN_SUCCESS', users: data });
        } catch (error) {
            dispatch({ type: 'USER_GET_ALL_ADMIN_FAILURE', error: error.message });
        }
    }
}


export const addAdminUser = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_ADD_ADMIN_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.post(`${API_URL}/auth/get-admin`, user, config);
            dispatch({ type: 'USER_ADD_ADMIN_SUCCESS', user: data });

        } catch (error) {
            dispatch({ type: 'USER_ADD_ADMIN_FAILURE', error: error.message });
        }
    }
}

export const userUpdateAdmin = (id, user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_EDIT_ADMIN_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.put(`${API_URL}/auth/get-admin/${id}`, user, config);
            dispatch({ type: 'USER_EDIT_ADMIN_SUCCESS', user: data });
        } catch (error) {
            dispatch({ type: 'USER_EDIT_ADMIN_FAILURE', error: error.message });
        }
    }
}

export const setRedirect = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_REDIRECT' });
        } catch (error) {
            dispatch({ type: 'SET_REDIRECT', error: error.message });
        }
    }
}