const carrier = {
    carriers: [],
    message: "",
    redirect: false,
    loading: false,
    error: null,
};

const Carrier = (state = carrier, action) => {
    switch (action.type) {
        case "GET_CARRIERS_REQUEST":
            return {
                ...state,
            };
        case "GET_CARRIERS_SUCCESS":
            return {
                ...state,
                loading: false,
                carriers: action.carriers,
            };
        case "GET_CARRIERS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "ADD_CARRIER_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "ADD_CARRIER_SUCCESS":
            return {
                ...state,
                loading: false,
                carriers: [...state.carriers, action.carrier],
                redirect: true,
            };
        case "ADD_CARRIER_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "DELETE_CARRIER_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_CARRIER_SUCCESS":
            return {
                ...state,
                loading: false,
                carriers: state.carriers.filter(
                    (carrier) => carrier._id !== action.id
                ),
            };
        case "DELETE_CARRIER_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "UPDATE_CARRIER_REQUEST":
            return {
                ...state,
                loading: true,
                redirect: false,
            };
        case "UPDATE_CARRIER_SUCCESS":
            return {
                ...state,
                loading: false,
                carriers: state.carriers.map((carrier) =>
                    carrier.id === action.carrier.id ? action.carrier : carrier
                ),
                redirect: true,
            };
        case "UPDATE_CARRIER_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                redirect: false,
            };
        case "GET_CARRIER_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_CARRIER_SUCCESS":
            return {
                ...state,
                loading: false,
                singleCarrier: action.carrier,
            };
        case "GET_CARRIER_FAILURE":
            return {
                ...state,
                loading: false,
            };
        case "LOAD_CARRIERS_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "LOAD_CARRIERS_SUCCESS":
            return {
                ...state,
                loading: false,
                carriers: action.carriers,
            };
        case "LOAD_CARRIERS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "SET_REDIRECTING":
            return {
                ...state,
                redirect: false,
            };

        default:
            return state;
    }
};

export default Carrier;
