import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;

export const getBrands = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_BRANDS_REQUEST" });
      const { data } = await axios.get(`${API_URL}/brand/admin`);
      dispatch({ type: "GET_BRANDS_SUCCESS", brands: data });
    } catch (error) {
      dispatch({ type: "GET_BRANDS_FAILURE", error: error.message });

    }
  };
};

export const addBrand = (brand) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "ADD_BRAND_REQUEST" });
      const { data } = await axios.post(`${API_URL}/brand`, brand, {
        headers: {
          "x-auth-token": `${localStorage.getItem("token")}`,
        },
      });
      dispatch({
        type: "ADD_BRAND_SUCCESS",
        brand: data.createdBrand,
      });
      toast.success('Brand added successfully!');
    } catch (error) {
      dispatch({ type: "ADD_BRAND_FAILURE", error: error.message });
      toast.error('Brand addition failed!');
    }
  };
};

export const deleteBrand = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "DELETE_BRAND_REQUEST" });
      await axios.delete(`${API_URL}/brand/${id}`, {
        headers: { "x-auth-token": `${localStorage.getItem("token")}` },
      });
      dispatch({ type: "DELETE_BRAND_SUCCESS", id });
      toast.success('Brand deleted successfully!');
    } catch (error) {
      dispatch({ type: "DELETE_BRAND_FAILURE", error: error.message });
      toast.error('Brand deletion failed!');
    }
  };
};

export const updateBrand = (id, brand) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_BRAND_REQUEST" });
      const { data } = await axios.put(`${API_URL}/brand/${id}`, brand, {
        headers: {
          "x-auth-token": `${localStorage.getItem("token")}`,
        },
      });
      dispatch({ type: "UPDATE_BRAND_SUCCESS", brand: data });
      toast.success('Brand updated successfully!');
    } catch (error) {
      dispatch({ type: "UPDATE_BRAND_FAILURE", error: error.message });
      toast.error('Brand update failed!');
    }
  };
};

export const loadBrands = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOAD_BRANDS_REQUEST" });
      dispatch({ type: "LOAD_BRANDS_SUCCESS", brands: data });
    } catch (error) {
      dispatch({ type: "LOAD_BRANDS_FAILURE", error: error.message });
    }
  };
};


export function setRedirecting() {
  return async dispatch => {
    dispatch({ type: 'SET_REDIRECTING' });
  }
}


export const setBrandStatus = (id, brand) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_BRAND_STATUS_REQUEST" });
      dispatch({ type: "UPDATE_BRAND_STATUS_SUCCESS", id, brand });
    } catch (error) {
      dispatch({ type: "UPDATE_BRAND_STATUS_FAILURE", error: error.message });
    }
  };
}