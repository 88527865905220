import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;


export const syncnow = () => {
    return async (dispatch) => {
        try {
            const { data } = await axios.get(`${API_URL}/posinven`).then((res) => {
                dispatch({ type: "ADD_SYNC_NOW_SUCCESS", syncnow: res.data });
                dispatch({ type: 'GET_PRODUCTS_SUCCESS', products: data });
            })
            toast.success('Inventory updated successfully!');
        } catch (error) {
            dispatch({ type: "ADD_SYNC_NOW_FAILURE", error: error.message });
            toast.error('Sync failed!');
        }
    };
};