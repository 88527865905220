import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import PulseLoader from "../../components/PulseLoader";
import { useSelector } from "react-redux";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from "react-date-range";
import Modal from "../../components/Modal";
import RetailerOrderSummary from "./RetailerOrderSummary";

const RetailerOrderListing = () => {
  const orders = useSelector((state) => state.Order.retailer_orders);
 
  // const Loading = useSelector((state) => state.Product.loading);
  const Loading = useSelector((state) => state.Order.loading);
  const category = useSelector((state) => state.Category.categories);
  const brands = useSelector((state) => state.Brand.brands);
  const refModal = useRef(null);
  const { openModal, closeModal } = Modal(refModal);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);

  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [filterCriteria, setFilterCriteria] = useState({
    startDate: null,
    endDate: null,
    status: null,
    orderID: null,
    userEmailandName: null,
    ProductName: null,
  });

  const columns = [
    {
      name: "ID",
      selector: (row) => row.orderId,
      sortable: true,
      width: "70px",
    },
    {
      name: "Order Date",
      selector: (row) => row.createdAt,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    // {
    //   name: "User Type",
    //   cell: (row) => row.customer?.type,
    //   width: "120px",
    // },
    {
      name: "User Name",
      cell: (row) => row?.customer?.firstName,
      width: "120px",
    },
    {
      name: "User Email",
      cell: (row) => row.customer?.email,
    },
    {
      name: " User Phone",
      cell: (row) => row.customer?.telBusiness
    },
    {
      name: "Amount Paid",
      selector: (row) => row?.grandTotal,
      sortable: true,
    },
    {
      name: "Order Details",
      cell: (row) => (
        <div className="d-flex">
          <Link to={`/retailer-order-details/${row._id}`}>
            <button className="btn btn-primary btn-sm">Manage</button>
          </Link>
          <button className="btn btn-danger btn-sm mx-3"  >Delete</button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    console.log("test");
    if (!Loading && orders.length > 0) {
      setFilteredOrders(orders);
    }
  }, [Loading, orders])


  let data = filteredOrders.map((order) => {
    return {
      orderId:order.orderId,
      _id: order._id,
      createdAt: new Date(order.createdAt).toLocaleDateString(),
      status: order.status,
      customer:order?.customer,

      grandTotal: order?.grandTotal,
      stores: order?.stores,
    };
  });


  const orderStatusOptions = [
    { value: "Received", label: "Received" },
    { value: "Processing", label: "Processing" },
    { value: "Shipped", label: "Shipped" },
    { value: "Delivered", label: "Delivered" },
    { value: "Cancelled", label: "Cancelled" },
  ];
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const handleDateChange = (item) => {
    setState([item.selection]);
    const startDate = new Date(item.selection.startDate);
    const endDate = new Date(item.selection.endDate);
    endDate.setDate(endDate.getDate() + 1);
    setFilterCriteria({ ...filterCriteria, startDate, endDate });
  };

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setFilterCriteria({ ...filterCriteria, status });
  };

  const handleOrderByUserandEmail = (e) => {
    const userEmailandName = e.target.value;
    setFilterCriteria({ ...filterCriteria, userEmailandName });
  };


  const handleOrderbyID = (e) => {
    const orderID = e.target.value;
    setFilterCriteria({ ...filterCriteria, orderID });
  };

  const handleCategoryChange = (e) => {
    const categoryValue = e.target.value;
    if (selectedCategories.includes(categoryValue)) {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== categoryValue));
    } else {
      setSelectedCategories([...selectedCategories, categoryValue]);
    }
  };

  const handleBrandChange = (e) => {
    const brandValue = e.target.value;
    if (selectedBrands.includes(brandValue)) {
      setSelectedBrands(selectedBrands.filter((brand) => brand !== brandValue));
    } else {
      setSelectedBrands([...selectedBrands, brandValue]);
    }
  };

  const handleOrderByName = (e) => {
    const ProductName = e.target.value;
    setFilterCriteria({ ...filterCriteria, ProductName });
  };

  useEffect(() => {
    applyFilters();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCriteria, selectedCategories, selectedBrands]);

  const applyFilters = () => {
    const {
      startDate,
      endDate,
      status,
      orderID,
      userEmailandName,
      ProductName,
    } = filterCriteria;

    const filtered = orders.filter((order) => {
      const orderDate = new Date(order.createdAt);
      const orderIdMatch = orderID ? order.id.toString().includes(orderID) : true;
      const statusMatch = status ? order.status === status : true;
      const ProductNameMatch = !ProductName || order.orderItems.some((item) => item.name.toLowerCase().includes(ProductName.toLowerCase()));
      const userEmailandNameMatch =
        !userEmailandName ||
        (order.customer &&
          (order.customer.firstName.toLowerCase().includes(userEmailandName.toLowerCase()) ||
            order.customer.email.toLowerCase().includes(userEmailandName.toLowerCase())));
      const categoryMatch =
        selectedCategories.length === 0 ||
        order.orderItems.some((item) =>
          selectedCategories.includes(item.category)
        );

      const brandMatch =
        selectedBrands.length === 0 ||
        order.orderItems.some((item) => selectedBrands.includes(item.brand));

      if (startDate && endDate) {
        return (
          orderDate >= startDate &&
          orderDate <= endDate &&
          orderIdMatch &&
          statusMatch &&
          userEmailandNameMatch &&
          categoryMatch &&
          brandMatch &&
          ProductNameMatch
        );
      } else if (startDate) {
        return (
          orderDate >= startDate &&
          orderIdMatch &&
          statusMatch &&
          userEmailandNameMatch &&
          categoryMatch &&
          brandMatch &&
          ProductNameMatch
        );
      } else if (endDate) {
        return (
          orderDate <= endDate &&
          orderIdMatch &&
          statusMatch &&
          userEmailandNameMatch &&
          categoryMatch &&
          brandMatch &&
          ProductNameMatch
        );
      }

      return (
        orderIdMatch &&
        statusMatch &&
        userEmailandNameMatch &&
        categoryMatch &&
        brandMatch &&
        ProductNameMatch
      );
    });

    setFilteredOrders(filtered);
  };
  return (
    <>
      <div className="content-wrapper container-fluid">
        {Loading ? (
          <PulseLoader />
        ) : (
          <>
            <div className="row p-5">
              <div className="d-flex justify-content-between mb-3">
                <div></div>
                <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" onClick={openModal}><i className='bx bx-filter-alt filter-icon' ></i>Filters</button>
              </div>
              <div className="card">
                <div className="d-flex justify-content-between">
                  <h5 className="card-header">All Orders</h5>
                </div>
                <div className="table-responsive text-nowrap">
                  <DataTable
                    columns={columns}
                    data={data}
                    defaultSortAsc="true"
                    pagination
                    responsive="true"
                    expandableRows
                    expandableRowsComponent={RetailerOrderSummary}
                    expandableRowExpanded={(row) => row._id === expandedRow}
                    onRowExpandToggled={(toggledRow, expanded) =>
                      setExpandedRow(expanded ? toggledRow._id : null)
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="content-backdrop fade"></div>
      </div>
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={refModal}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div></div>
              <button type="button" className="close-modal" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="order-filters">
                <h4 className="text-left">Filters</h4>

                <div className="col-md-12 mb-3">
                  <div className="row">
                    <div className="col-sm-3 mx-3">
                      <div className="form-group">
                        <DateRange
                          editableDateInputs={true}
                          onChange={handleDateChange}
                          moveRangeOnFirstSelection={false}
                          ranges={state}
                          maxDate={new Date()}
                          staticRanges={[]}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-3">
                        <label className="filter-label" htmlFor="status">Status</label>
                        <select className="form-control" onChange={handleStatusChange}>
                          <option value="">Select Status</option>
                          {orderStatusOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label className="filter-label" htmlFor="status">Order #</label>
                        <input type="text" className="form-control" placeholder="Search by Order #" onChange={handleOrderbyID} />
                      </div>
                      <div className="form-group mb-3">
                        <label className="filter-label" htmlFor="status">User/Email</label>
                        <input type="text" className="form-control" placeholder="Search by user or email" onChange={handleOrderByUserandEmail} />
                      </div>
                      <div className="form-group mb-3">
                        <label className="filter-label" htmlFor="status">Name</label>
                        <input type="text" className="form-control" placeholder="Search by name" onChange={handleOrderByName} />
                      </div>

                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-3">
                        <label className="filter-label" htmlFor="category">Category</label>
                        <div className="filter-overflow">
                          {category.map((cat) => (
                            <div className="form-check" key={cat._id}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={cat._id}
                                id={cat._id}
                                onChange={handleCategoryChange}
                              />
                              <label className="form-check-label" htmlFor={cat._id}>
                                {cat.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group filter-overflow mb-3">
                        <label className="filter-label" htmlFor="brand">Brand</label>
                        <div className="filter-overflow">
                          {brands.map((brand) => (
                            <div className="form-check" key={brand._id}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={brand._id}
                                id={brand._id}
                                onChange={handleBrandChange}
                              />
                              <label className="form-check-label" htmlFor={brand._id}>
                                {brand.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
    
  
};

export default RetailerOrderListing;
