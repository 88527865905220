import { React } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PulseLoader from '../components/PulseLoader';

import { useSelector, useDispatch } from 'react-redux';

const { deleteDesign } = require('../actions/designActions');
const { getProducts } = require('../actions/productActions');

const Design = () => {

    const dispatch = useDispatch();
    const { designs, loading } = useSelector(state => state.Design);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteDesign(id));
            dispatch(getProducts());
        }
    }



    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Action',
            cell: row => <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu">
                    <Link className="dropdown-item" to={{
                        pathname: `/designs/edit-design/${row.id}`
                    }}><i className="bx bx-edit-alt me-1"></i> Edit</Link>
                    <div className="dropdown-item" onClick={() => { handleDelete(row.desId) }} ><i className="bx bx-trash me-1"></i> Delete</div>
                </div>
            </div>,
            width: '100px',
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Slug',
            selector: row => row.slug,
        },

    ];

    const data = designs.map(des => {
        return {
            desId: des._id,
            id: des.id,
            name: des.name,
            slug: des.slug,
        }
    })


    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    loading ? (<PulseLoader />) : (
                        <div className="row p-5">
                            <div className="card">
                                <div className='d-flex justify-content-between'>
                                    <h5 className="card-header">All Design</h5>
                                    <div>
                                        <Link to='/designs/add-design' className="btn btn-outline-primary my-3">
                                            <span className="tf-icons bx bx-plus-circle"></span>&nbsp; Add Design
                                        </Link>

                                    </div>
                                </div>
                                <div className="table-responsive text-nowrap">
                                    <DataTable columns={columns} data={data} defaultSortAsc='true' pagination responsive='true' />
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default Design