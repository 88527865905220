import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
// import ImageField from '../components/InputFields/ImageField';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import MultipleImageField from "../components/InputFields/MultipleImageField";

const { addCarrier, updateCarrier } = require('../actions/carrierActions');


const CarrierForm = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { FormName, Editable } = props;
  const { loading } = useSelector(state => state.Carrier);
  const { carriers, redirect } = useSelector(state => state.Carrier);
  const [carrier, setCarr] = useState({});
  const [Carrier, setCarrier] = useState({
    name: '',
    priorityNumber:'',
    photo: [],
  });

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
      const carr = carriers.find(carrier => carrier.id == id);
      if (carr) {
        setCarr(carr);
        setCarrier({
          name: carr.name,
          priorityNumber:carr?.priorityNumber,
          photo: carr.photo
        });
      }
    }

    // eslint-disable-next-line
  }, [id, carriers]);

  useEffect(() => {
    if (redirect) {
      navigate('/carriers');
    }
    // eslint-disable-next-line
  }, [redirect]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCarrier({ ...Carrier, [name]: value });
  };

  const handleAddCarrier = (e) => {
    e.preventDefault();
    if (!Carrier.name) {
      toast.error('Please enter carrier name');
      return;
    }
    if (!Carrier.priorityNumber) {
      toast.error('Please enter priority number');
      return;
    }

    dispatch(addCarrier(Carrier));
    // toast.success('Carrier added');
  }

  const handleUpdateCarrier = (e) => {
    e.preventDefault();
    if (!Carrier.name) {
      toast.error('Please enter carrier name');
      return;
    }
    if (!Carrier.priorityNumber) {
      toast.error('Please enter priority number');
      return;
    }
    dispatch(updateCarrier(carrier._id, Carrier));
  }


  return (
    <>
      <div className="content-wrapper container-fluid">
        {

          loading ? (<PulseLoader />) : (
            <div className="row p-5 justify-content-center">
              <div className="col-md-5">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">{FormName}</h5>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className='row'>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <InputField label="Name" name='name' value={Carrier.name} onChange={handleInputChange} required={true} type='text' placeholder="Name here..." />
                          </div>
                          <div className="mb-3">
                            <InputField label="Priority number" name='priorityNumber' value={Carrier.priorityNumber} onChange={handleInputChange} required={true} type='text' placeholder="Priority number here..." />
                          </div>
                        </div>
                      </div>

                      {
                        Editable === 'true' ? <button type="submit" onClick={handleUpdateCarrier} className="btn btn-primary">
                          Update Carrier
                        </button> : <button type="submit" onClick={handleAddCarrier} className="btn btn-primary">
                          Save
                        </button>
                      }

                    </form>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className="content-backdrop fade"></div>
      </div>
    </>
  )
}

export default CarrierForm