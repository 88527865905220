import { React, useEffect, useState } from 'react'
import InputField from '../components/InputFields/InputField'
import { useDispatch, useSelector } from 'react-redux';
import { updateTax } from '../actions/taxActions';
import PulseLoader from '../components/PulseLoader';
const TaxNew = () => {
    const dispatch = useDispatch();
    const { taxes, loading } = useSelector(state => state.Tax);
    const [tax, setTax] = useState(0);
    useEffect(() => {
        setTax(taxes[0]?.tax);
        // eslint-disable-next-line
    }, [taxes]);

    const update = (e) => {
        e.preventDefault();
        dispatch(updateTax(taxes[0]._id, tax));
    }

    return (
        <>
            <div className="content-wrapper container-fluid">

                {
                    loading ? (<PulseLoader />) : (
                        <>
                            <div className="row p-5">
                                <div className="col-md-3">
                                    <div className="card mb-4">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <h5 className="mb-0">Tax</h5>
                                        </div>
                                        <div className="card-body">
                                            <form>
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <InputField label="Tax for Consumer" value={tax} onChange={(e) => { setTax(e.target.value) }} required={true} type='number' placeholder="Tax here... Ex. 8.25" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary" onClick={update}>Save</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-backdrop fade"></div>
                        </>
                    )
                }
            </div>
        </>


    )
}

export default TaxNew