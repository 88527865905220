import axios from 'axios';
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST



export const getAllOrders = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ORDERS_GET_ALL_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat') || '',
                },
            };
            const { data } = await axios.get(`${API_URL}/orders`, config);
            dispatch({ type: 'ORDERS_GET_ALL_SUCCESS', orders: data });
        } catch (error) {
            dispatch({ type: 'ORDERS_GET_ALL_FAILURE', error: error.message });
        }
    }
}
export const getAllRetailerOrders = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'RETAILER_ORDERS_GET_ALL_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat') || '',
                },
            };
            const { data } = await axios.get(`${API_URL}/orders/retailer-orders`, config);
            dispatch({ type: 'RETAILER_ORDERS_GET_ALL_SUCCESS', orders: data });
        } catch (error) {
            dispatch({ type: 'RETAILER_ORDERS_GET_ALL_FAILURE', error: error.message });
        }
    }
}



export const loadOrders = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'LOAD_ORDERS_REQUEST' });
            dispatch({ type: 'LOAD_ORDERS_SUCCESS', products: data });
        } catch (error) {
            dispatch({ type: 'LOAD_ORDERS_FAILURE', error: error.message });
        }
    }
}

export const updateOrderStatus = (id, status) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'UPDATE_ORDER_STATUS_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat') || '',
                },
            };
            const { data } = await axios.put(`${API_URL}/orders/admin/${id}`, { status }, config);
            dispatch({ type: 'UPDATE_ORDER_STATUS_SUCCESS', order: data });
            toast.success('Order status updated successfully');
        } catch (error) {
            dispatch({ type: 'UPDATE_ORDER_STATUS_FAILURE', error: error.message });
        }
    }
}
export const updateRetailerOrderStatus = (id, status) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'UPDATE_ORDER_STATUS_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat') || '',
                },
            };
            const { data } = await axios.put(`${API_URL}/orders/retailer-orders/${id}`, { status }, config);
            dispatch({ type: 'UPDATE_RETAILER_ORDER_STATUS_SUCCESS', order: data });
            toast.success('Order status updated successfully');
        } catch (error) {
            dispatch({ type: 'UPDATE_RETAILER_ORDER_STATUS_FAILURE', error: error.message });
        }
    }
}

export const deleteOrder = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'DELETE_ORDER_REQUEST' });
            await axios.delete(`${API_URL}/orders/${id}`);
            dispatch({ type: 'DELETE_ORDER_SUCCESS', id });
            toast.success('Order deleted successfully');
        } catch (error) {
            dispatch({ type: 'DELETE_ORDER_FAILURE', error: error.message });
        }
    }
}

export const getOrdersReport = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ORDERS_REPORT_REQUEST' });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat') || '',
                },
            };
            const { data } = await axios.get(`${API_URL}/report/orders`, config);
            dispatch({ type: 'ORDERS_REPORT_SUCCESS', report: data });
        } catch (error) {
            dispatch({ type: 'ORDERS_REPORT_FAILURE', error: error.message });
        }
    }
}