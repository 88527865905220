import React, { useEffect, useState, useRef } from "react";
import Papa from 'papaparse';
import DataTable from "react-data-table-component";
import PulseLoader from "../components/PulseLoader";
import { useDispatch, useSelector } from "react-redux";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from "react-date-range";
import Modal from "../components/Modal";
import { getOrdersReport } from "../actions/orderActions";

const OrderReport = () => {
    const dispatch = useDispatch();

    const { report, loading } = useSelector((state) => state.Order);
    const category = useSelector((state) => state.Category.categories);
    const { subCategories } = useSelector((state) => state.SubCategory);
    const { models } = useSelector((state) => state.Models);
    const { carriers } = useSelector((state) => state.Carrier);

    const brands = useSelector((state) => state.Brand.brands);
    const refModal = useRef(null);
    const { openModal, closeModal } = Modal(refModal);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedCarriers, setSelectedCarriers] = useState([]);
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [filteredModels, setFilteredModels] = useState([]);
    const [filteredReport, setFilteredReport] = useState(report);
    const [filterCriteria, setFilterCriteria] = useState({
        startDate: null,
        endDate: null,
    });

    useEffect(() => {
        dispatch(getOrdersReport());
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const columns = [
        {
            name: "Sr #",
            selector: (row) => row.id,
            sortable: true,
            width: "70px",
        },
        {
            name: "Product Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Sold",
            selector: (row) => row.sold,
            sortable: true,
        },
        {
            name: "Category",
            cell: (row) => row.category,
        },
        {
            name: "Sub Category",
            cell: (row) => row.subCategory,
        },
        {
            name: "Brand",
            cell: (row) => row.brand,
        },
        {
            name: "Model",
            cell: (row) => row.model,
        },
        {
            name: "Design",
            cell: (row) => row.design,
        },
        {
            name: "Carrier",
            cell: (row) => row.carrier,
        },
    ];

    useEffect(() => {
        const subCat = subCategories.filter((subCat) =>
            selectedCategories.includes(subCat.category?._id)
        );
        setFilteredSubCategories(subCat);
    }, [selectedCategories, subCategories]);

    useEffect(() => {
        const model = models.filter((model) =>
            selectedBrands.includes(model.brand?._id)
        );
        setFilteredModels(model);
    }, [selectedBrands, models]);


    const [data, setDate] = useState([]);
    useEffect(() => {
        if (filteredReport.length > 0) {
            const da = filteredReport.map((order, index) => {
                return {
                    id: index + 1,
                    name: order.item.name,
                    sold: order.sold,
                    category: order.item.category?.name || "N/A",
                    subCategory: order.item.subcategory?.name || "N/A",
                    brand: order.item.brand?.name || "N/A",
                    model: order.item.model?.name || "N/A",
                    design: order.item.design?.name || "N/A",
                    carrier: order.item.carrier.name || "N/A",
                };
            });
            setDate(da);
        }
    }, [filteredReport]);


    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleDateChange = (item) => {
        setState([item.selection]);
        const startDate = new Date(item.selection.startDate);
        const endDate = new Date(item.selection.endDate);
        endDate.setDate(endDate.getDate() + 1);
        setFilterCriteria({ ...filterCriteria, startDate, endDate });
    };


    const handleCategoryChange = (e) => {
        const categoryValue = e.target.value;
        if (selectedCategories.includes(categoryValue)) {
            setSelectedCategories(selectedCategories.filter((cat) => cat !== categoryValue));
        } else {
            setSelectedCategories([...selectedCategories, categoryValue]);
        }
    };

    const handleSubCategoryChange = (e) => {
        const subCategoryValue = e.target.value;
        if (selectedSubCategories.includes(subCategoryValue)) {
            setSelectedSubCategories(selectedSubCategories.filter((cat) => cat !== subCategoryValue));
        } else {
            setSelectedSubCategories([...selectedSubCategories, subCategoryValue]);
        }
    };

    const handleModelChange = (e) => {
        const modelValue = e.target.value;
        if (selectedModels.includes(modelValue)) {
            setFilteredModels(selectedModels.filter((brand) => brand !== modelValue));
        } else {
            setSelectedModels([...selectedModels, modelValue]);
        }
    };

    const handleCarrierChange = (e) => {
        const carrierValue = e.target.value;
        if (selectedCarriers.includes(carrierValue)) {
            setSelectedCarriers(selectedCarriers.filter((brand) => brand !== carrierValue));
        } else {
            setSelectedCarriers([...selectedCarriers, carrierValue]);
        }
    };

    const handleBrandChange = (e) => {
        const brandValue = e.target.value;
        if (selectedBrands.includes(brandValue)) {
            setSelectedBrands(selectedBrands.filter((brand) => brand !== brandValue));
        } else {
            setSelectedBrands([...selectedBrands, brandValue]);
        }
    };


    useEffect(() => {
        applyFilters();
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterCriteria, selectedCategories, selectedBrands, selectedSubCategories, selectedModels, selectedCarriers]);

    const applyFilters = () => {
        const {
            startDate,
            endDate
        } = filterCriteria;

        const filtered = report.filter((report) => {
            const orderDate = new Date(report.soldAt);
            const categoryMatch =
                selectedCategories.length === 0 ||
                selectedCategories.includes(report.item.category?._id);

            const brandMatch =
                selectedBrands.length === 0 ||
                selectedBrands.includes(report.item.brand?._id);

            const subCategoryMatch = selectedSubCategories.length === 0 || selectedSubCategories.includes(report.item.subcategory?._id);

            const modelMatch = selectedModels.length === 0 || selectedModels.includes(report.item.model?._id);

            const carrierMatch = selectedCarriers.length === 0 || selectedCarriers.includes(report.item.carrier?._id);

            if (startDate && endDate) {
                return (
                    orderDate >= startDate &&
                    orderDate <= endDate &&
                    categoryMatch &&
                    brandMatch &&
                    subCategoryMatch &&
                    modelMatch &&
                    carrierMatch

                );
            } else if (startDate) {
                return (
                    orderDate >= startDate &&

                    categoryMatch &&
                    brandMatch &&
                    subCategoryMatch &&
                    modelMatch &&
                    carrierMatch

                );
            } else if (endDate) {
                return (
                    orderDate <= endDate &&
                    categoryMatch &&
                    brandMatch &&
                    subCategoryMatch &&
                    modelMatch &&
                    carrierMatch

                );
            }

            return (

                categoryMatch &&
                brandMatch &&
                subCategoryMatch &&
                modelMatch &&
                carrierMatch

            );
        });

        setFilteredReport(filtered);
    };


    const csvData = filteredReport.map((order, index) => {
        return {
            "Id": index + 1,
            "Name": order.item.name,
            "Sold": order.sold,
            "Category": order.item.category.name || "N/A",
            "Sub Category": order.item.subcategory?.name || "N/A",
            "Brand": order.item.brand?.name || "N/A",
            "Model": order.item.model?.name || "N/A",
            "Design": order.item.design?.name || "N/A",
            "Carrier": order.item.carrier.name || "N/A",
        }
    })

    const csv = Papa.unparse(csvData);
    const csvDownload = () => {
        const element = document.createElement("a");
        const file = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        element.href = URL.createObjectURL(file);
        element.download = `${new Date().toLocaleDateString()}-sale.csv`;
        document.body.appendChild(element);
        element.click();
    }


    return (
        <>
            <div className="content-wrapper container-fluid">
                {loading ? (
                    <PulseLoader />
                ) : (
                    <>
                        <div className="row p-5">
                            <div className="d-flex justify-content-between mb-3">
                                <div></div>
                                <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" onClick={openModal}><i className='bx bx-filter-alt filter-icon' ></i>Filters</button>
                            </div>
                            <div className="card">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h5 className="card-header">Sale Report</h5>
                                    </div>
                                    <div className="align-self-center">
                                        <button className="btn btn-primary" onClick={csvDownload}>Export Report</button>
                                    </div>
                                </div>
                                <div className="table-responsive text-nowrap">
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        defaultSortAsc="true"
                                        pagination
                                        responsive="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="content-backdrop fade"></div>
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={refModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div></div>
                            <button type="button" className="close-modal" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="order-filters">
                                <h4 className="text-left">Filters</h4>

                                <div className="col-md-12 mb-3">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <DateRange
                                                    editableDateInputs={true}
                                                    onChange={handleDateChange}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={state}
                                                    maxDate={new Date()}
                                                    staticRanges={[]}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group mb-3">
                                                <label className="filter-label" htmlFor="category">Category</label>
                                                <div className="filter-overflow">
                                                    {category.map((cat) => (
                                                        <div className="form-check" key={cat._id}>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={cat._id}
                                                                id={cat._id}
                                                                onChange={handleCategoryChange}
                                                            />
                                                            <label className="form-check-label" htmlFor={cat._id}>
                                                                {cat.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            filteredSubCategories.length > 0 &&
                                            (
                                                <div className="col-sm-4">
                                                    <div className="form-group mb-3">
                                                        <label className="filter-label" htmlFor="brand">Sub Category</label>
                                                        <div className="filter-overflow">
                                                            {filteredSubCategories.map((subCat) => (
                                                                <div className="form-check" key={subCat._id}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={subCat._id}
                                                                        id={subCat._id}
                                                                        onChange={handleSubCategoryChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={subCat._id}>
                                                                        {subCat.name}
                                                                    </label>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        }
                                        <hr />
                                        <div className="col-sm-4">
                                            <div className="form-group mb-3">
                                                <label className="filter-label" htmlFor="status">Carrier</label>
                                                <div className="filter-overflow">
                                                    {carriers.map((carrier) => (
                                                        <div className="form-check" key={carrier._id}>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={carrier._id}
                                                                id={carrier._id}
                                                                onChange={handleCarrierChange}
                                                            />
                                                            <label className="form-check-label" htmlFor={carrier._id}>
                                                                {carrier.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group filter-overflow mb-3">
                                                <label className="filter-label" htmlFor="brand">Brand</label>
                                                <div className="filter-overflow">
                                                    {brands.map((brand) => (
                                                        <div className="form-check" key={brand._id}>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={brand._id}
                                                                id={brand._id}
                                                                onChange={handleBrandChange}
                                                            />
                                                            <label className="form-check-label" htmlFor={brand._id}>
                                                                {brand.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            filteredModels.length > 0 &&
                                            (
                                                <div className="col-sm-3">
                                                    <div className="form-group mb-3">
                                                        <label className="filter-label" htmlFor="status">Model</label>
                                                        <div className="filter-overflow">
                                                            {filteredModels.map((model) => (
                                                                <div className="form-check" key={model._id}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={model._id}
                                                                        id={model._id}
                                                                        onChange={handleModelChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={model._id}>
                                                                        {model.name}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default OrderReport;
