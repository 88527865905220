import React from 'react'

const OrderItems = ({ data }) => {
    return (
        <div className='expanded-order-row'>
            <h2 className="order_details-heading">
                Order Items
            </h2>
            <div className="card mb-3">
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Image</th>
                                <th scope="col">Name</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.orderItems.map((item) => {
                                return (
                                    <tr key={item._id}>
                                        <td>
                                            <img
                                                src={item.image}
                                                alt={item.name}
                                                style={{ width: "100px", height: "100px" }}
                                            />
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.price}</td>
                                    </tr>
                                );
                            }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default OrderItems