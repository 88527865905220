import React from 'react'
import { useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  RadialLinearScale,
  PointElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineController,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  RadialLinearScale,
  PointElement,
  LineElement
);

const Dashboard = () => {
  const { products } = useSelector(state => state.Product);
  const { orders } = useSelector(state => state.Order);
  const { categories } = useSelector(state => state.Category);
  const { userList } = useSelector(state => state.Auth);
  const productlengthcategory = categories.map(cat => {
    return {
      name: cat?.name,
      length: products?.filter(prod => prod?.category?._id === cat._id).length
    }
  })

  const monthwiseorders = orders.map(order => {
    return {
      month: new Date(order.createdAt).getMonth(),
      year: new Date(order.createdAt).getFullYear(),
      total: order.totalPrice
    }
  })

  const getOrdersLength = (month, year) => {
    return monthwiseorders.filter(order => order.month === month && order.year === year).length
  }

  const dataforProducts = {
    labels: categories.map(cat => cat.name),
    datasets: [
      {
        label: "Products",
        data: productlengthcategory.map(cat => cat.length),
        backgroundColor: "#696cff",
        borderColor: "#696cff",
        borderWidth: 1,
      },
    ],
  }

  const pastSixMonths = new Array(6).fill(0).map((_, i) => {
    const date = new Date();
    date.setMonth(date.getMonth() - i);
    return date.toLocaleString("default", { month: "long" });
  }).reverse();


  const dataLineChart = {
    labels: pastSixMonths,
    datasets: [
      {
        label: "Orders",
        data: new Array(7).fill(0).map((_, i) => getOrdersLength(i + new Date().getMonth() - 5, 2023)),
        fill: false,
        borderColor: "#696cff",
        backgroundColor: "#696cff",
        tension: 0.1,
      },
    ],
  };

  const optionsLineChart = {
    scales: {
      y: {
        beginAtZero: true,
      },

    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const userRegistrationRatio = userList.map((user) => {
    return {
      month: new Date(user.createdAt).getMonth(),
      year: new Date(user.createdAt).getFullYear(),
      status: user.isApproved,
    };
  });

  const getRegisteredUsersLength = (month, year) => {
    return userRegistrationRatio.filter(
      (user) => user.month === month && user.year === year
    ).length;
  };

  const data = {
    labels: pastSixMonths,
    datasets: [
      {
        label: "Users",
        data: new Array(7)
          .fill(0)
          .map((_, i) =>
            getRegisteredUsersLength(i + new Date().getMonth() - 5, 2023)
          ),
        fill: false,
        borderColor: "#696cff",
        backgroundColor: "#696cff",
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="content-wrapper container-fluid" >
      <div className="row">
        <h3 className='mb-3'>Remax Analytics</h3>

        <div className="col-md-6 mb-4">
          <div className='card p-5'>
            <h3 className="text-center">Orders History</h3>
            <div className="card-body">
              <Line options={optionsLineChart} data={dataLineChart} />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className='card p-5'>
            <h3 className="text-center">Products by Categories</h3>
            <div className="card-body">
              <Bar data={dataforProducts} options={optionsLineChart} />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card p-5">
            <h3 className="text-center">Users Registered In Past 6 Months</h3>
            <div className="card-body">
              <Bar data={data} options={optionsLineChart} />
            </div>
          </div>
        </div>
        <div className="col-md-8"></div>
      </div>
    </div >
  )
}

export default Dashboard