const product = {
  products: [],
  redirect: false,
  loading: false,
  error: null,
};

const Product = (state = product, action) => {
  switch (action.type) {
    case "GET_PRODUCTS_REQUEST":
      return {
        ...state,
        loading: true,
        redirect: false,
      };
    case "GET_PRODUCTS_SUCCESS":
      return {
        ...state,
        loading: false,
        products: action.products,
        redirect: false,
      };
    case "GET_PRODUCTS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
        redirect: false,
      };
    case "ADD_PRODUCT_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ADD_PRODUCT_SUCCESS":
      return {
        ...state,
        loading: false,
        products: [action.product, ...state.products],
        redirect: true,
      };
    case "ADD_PRODUCT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
        redirect: false,
      };

    case "DELETE_PRODUCT_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "DELETE_PRODUCT_SUCCESS":
      return {
        ...state,
        loading: false,
        products: state.products.filter((product) => product._id !== action.id),
      };
    case "DELETE_PRODUCT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "UPDATE_PRODUCT_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_PRODUCT_SUCCESS":
      return {
        ...state,
        loading: false,
        products: state.products.map((product) =>
          product.id === action.product.id ? action.product : product
        ),
        redirect: true,
      };
    case "UPDATE_PRODUCT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
        redirect: false,
      };

    case "GET_SINGLE_PRODUCT_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_SINGLE_PRODUCT_SUCCESS":
      return {
        ...state,
        loading: false,
        singleProduct: action.product,
      };
    case "GET_SINGLE_PRODUCT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "LOAD_PRODUCTS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "LOAD_PRODUCTS_SUCCESS":
      return {
        ...state,
        loading: false,
        products: action.products,
      };
    case "LOAD_PRODUCTS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "SET_REDIRECTING":
      return {
        ...state,
        redirect: false,
      };
    default:
      return state;
  }
};

export default Product;
