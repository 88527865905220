import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PulseLoader from '../components/PulseLoader';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Papa from 'papaparse';
import { getProducts } from '../actions/productActions';
import toast from 'react-hot-toast';

const ProductImport = () => {

    const host = process.env.REACT_APP_API_HOST;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState("");
    const [fileLoading, setFileLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
        Papa.parse(file, {
            complete: function (results) {
                setData(results.data);
            }
        });
    };

    const handleSave = async (e) => {
        setFileLoading(true);
        e.preventDefault();

        let arrayData = data.slice(1, data.length - 1);
        let parseData = [];
        arrayData.map((item) => {
            if (item[0] === "") {
                return null;
            }
            let data = {
                id: item[0],
                name: item[1],
                category: item[2],
                subcategory: item[3],
                skuNumber: item[4],
                barCode: item[5],
                price: item[6],
                retailerPrice: item[7],
                dropshipperPrice: item[8],
                stock: item[9],
                bestSeller: item[10],
                brand: item[11],
                model: item[12],
                design: item[13],
                carrier: item[14],
                weight: item[15],
                description: item[16],
            };
            parseData.push(data);

            return null;
        });

        const formData = new FormData();
        formData.append('file', file);
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 92);
                    setProgress(progress);
                },
            };

            axios.put(`${host}/products/import`, formData, config)
                .then((res) => {
                    dispatch(getProducts());
                    setFileLoading(false);
                    setProgress(100);
                    navigate('/products');
                    toast.success('Products imported successfully!');
                })
                .catch((err) => {
                    setProgress(0);
                    setFileLoading(false);
                })
                .finally(() => {
                    setProgress(0);
                    setFileLoading(false);
                }
                );
        } catch (e) {
            setFileLoading(false);
        }
    };

    const now = progress;

    return (
        <>
            <div className="content-wrapper">
                {
                    <div className="row p-5 justify-content-center">
                        <div className="col-md-5">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">Import Products</h5>
                                </div>
                                <div className="card-body">
                                    <form encType='multipart/form-data'>
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label htmlFor="importFile" className='mb-2'>Upload CSV</label>
                                                    <input type='file' className='form-control mb-3' name="file" onChange={handleChange} accept=".csv" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            {fileLoading ? (<ProgressBar now={now} label={`${now}%`} />
                                            ) : (null)}
                                        </div>
                                        <button type="submit" onClick={handleSave} className="btn btn-primary" disabled={fileLoading ? true : false}>
                                            Import
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default ProductImport