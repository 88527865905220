import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
// import ImageField from '../components/InputFields/ImageField';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
// import MultipleImageField from "../components/InputFields/MultipleImageField";

const { addDesign, updateDesign, setRedirecting } = require('../actions/designActions');


const DesignForm = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { FormName, Editable } = props;
    const { designs, redirect, loading } = useSelector(state => state.Design);
    const [design, setDesig] = useState({});
    const [Design, setDesign] = useState({
        name: '',
        photo: [],
    });
    // const [fileLoading, setFileLoading] = useState(false);
    // const [files, setFiles] = useState([]);

    useEffect(() => {
        if (id) {
            // eslint-disable-next-line
            const desig = designs.find(design => design.id == id);
            if (desig) {
                setDesig(desig);
                setDesign({
                    name: desig.name,
                    // photo: desig.photo
                });
            }
        }

        // eslint-disable-next-line
    }, [id, designs]);

    useEffect(() => {
        if (redirect) {
            navigate('/designs');
        }
        dispatch(setRedirecting())
        // eslint-disable-next-line
    }, [redirect]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDesign({ ...Design, [name]: value });
    };

    const handleAddDesign = (e) => {
        e.preventDefault();
        if (Design.name === '') {
            toast.error('Please enter design name');
            return;
        } else if (Design.name !== '') {
            // Design.photo = files;
            dispatch(addDesign(Design));
        }
        // toast.success('Design added');
    }

    const handleUpdateDesign = (e) => {
        e.preventDefault();
        // Design.photo = files;
        dispatch(updateDesign(design._id, Design));
    }


    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    loading ? (<PulseLoader />) : (
                        <div className="row p-5 justify-content-center">
                            <div className="col-md-5">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">{FormName}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <InputField label="Design" name='name' value={Design.name} onChange={handleInputChange} required={true} type='text' placeholder="Design here..." />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <MultipleImageField name="photos" setFiles={setFiles} data={Design.photo} setFileLoading={setFileLoading} />
                                                    </div>
                                                </div> */}
                                            </div>

                                            {
                                                // Editable === 'true' ? <button type="submit" onClick={handleUpdateDesign} className="btn btn-primary" disabled={fileLoading ? true : false}>
                                                //     Update Design
                                                // </button> : <button type="submit" onClick={handleAddDesign} className="btn btn-primary" disabled={fileLoading ? true : false}>
                                                //     Save
                                                // </button>
                                                Editable === 'true' ? <button type="submit" onClick={handleUpdateDesign} className="btn btn-primary">
                                                    Update Design
                                                </button> : <button type="submit" onClick={handleAddDesign} className="btn btn-primary">
                                                    Save
                                                </button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default DesignForm