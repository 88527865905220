import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Select from "react-select";

const { addTax, updateTax, setRedirecting } = require('../actions/taxActions');


const TaxForm = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { FormName, Editable } = props;
    const { taxes, redirect, loading } = useSelector(state => state.Tax);
    const [tax, setTa] = useState({});
    const [Tax, setTax] = useState({
        state: '',
        tax: '',
        customerType: '',
    });

    useEffect(() => {
        if (id) {
            // eslint-disable-next-line
            const taxx = taxes.find(tax => tax.id == id);
            if (taxx) {
                setTa(taxx);
                setTax({
                    state: taxx.state,
                    tax: taxx.tax,
                    customerType: taxx.customerType,
                });
            }
        }

        // eslint-disable-next-line
    }, [id, taxes]);

    useEffect(() => {
        if (redirect) {
            navigate('/taxes');
        }
        dispatch(setRedirecting())
        // eslint-disable-next-line
    }, [redirect]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTax({ ...Tax, [name]: value });
    };

    const selectCustomer = [
        { value: 'Retailer', label: 'Retailer' },
        { value: 'Dropshipper', label: 'Dropshipper' },
        { value: 'Consumer', label: 'Consumer' }
    ];

    const handleCustomer = (e) => {
        setTax({
            ...Tax,
            customerType: e.value,
        });
    };

    const selectState = [
        {
            label: "Alabama",
            value: "AL"
        },
        {
            label: "Alaska",
            value: "AK"
        },
        {
            label: "American Samoa",
            value: "AS"
        },
        {
            label: "Arizona",
            value: "AZ"
        },
        {
            label: "Arkansas",
            value: "AR"
        },
        {
            label: "California",
            value: "CA"
        },
        {
            label: "Colorado",
            value: "CO"
        },
        {
            label: "Connecticut",
            value: "CT"
        },
        {
            label: "Delaware",
            value: "DE"
        },
        {
            label: "District Of Columbia",
            value: "DC"
        },
        {
            label: "Federated States Of Micronesia",
            value: "FM"
        },
        {
            label: "Florida",
            value: "FL"
        },
        {
            label: "Georgia",
            value: "GA"
        },
        {
            label: "Guam",
            value: "GU"
        },
        {
            label: "Hawaii",
            value: "HI"
        },
        {
            label: "Idaho",
            value: "ID"
        },
        {
            label: "Illinois",
            value: "IL"
        },
        {
            label: "Indiana",
            value: "IN"
        },
        {
            label: "Iowa",
            value: "IA"
        },
        {
            label: "Kansas",
            value: "KS"
        },
        {
            label: "Kentucky",
            value: "KY"
        },
        {
            label: "Louisiana",
            value: "LA"
        },
        {
            label: "Maine",
            value: "ME"
        },
        {
            label: "Marshall Islands",
            value: "MH"
        },
        {
            label: "Maryland",
            value: "MD"
        },
        {
            label: "Massachusetts",
            value: "MA"
        },
        {
            label: "Michigan",
            value: "MI"
        },
        {
            label: "Minnesota",
            value: "MN"
        },
        {
            label: "Mississippi",
            value: "MS"
        },
        {
            label: "Missouri",
            value: "MO"
        },
        {
            label: "Montana",
            value: "MT"
        },
        {
            label: "Nebraska",
            value: "NE"
        },
        {
            label: "Nevada",
            value: "NV"
        },
        {
            label: "New Hampshire",
            value: "NH"
        },
        {
            label: "New Jersey",
            value: "NJ"
        },
        {
            label: "New Mexico",
            value: "NM"
        },
        {
            label: "New York",
            value: "NY"
        },
        {
            label: "North Carolina",
            value: "NC"
        },
        {
            label: "North Dakota",
            value: "ND"
        },
        {
            label: "Northern Mariana Islands",
            value: "MP"
        },
        {
            label: "Ohio",
            value: "OH"
        },
        {
            label: "Oklahoma",
            value: "OK"
        },
        {
            label: "Oregon",
            value: "OR"
        },
        {
            label: "Palau",
            value: "PW"
        },
        {
            label: "Pennsylvania",
            value: "PA"
        },
        {
            label: "Puerto Rico",
            value: "PR"
        },
        {
            label: "Rhode Island",
            value: "RI"
        },
        {
            label: "South Carolina",
            value: "SC"
        },
        {
            label: "South Dakota",
            value: "SD"
        },
        {
            label: "Tennessee",
            value: "TN"
        },
        {
            label: "Texas",
            value: "TX"
        },
        {
            label: "Utah",
            value: "UT"
        },
        {
            label: "Vermont",
            value: "VT"
        },
        {
            label: "Virgin Islands",
            value: "VI"
        },
        {
            label: "Virginia",
            value: "VA"
        },
        {
            label: "Washington",
            value: "WA"
        },
        {
            label: "West Virginia",
            value: "WV"
        },
        {
            label: "Wisconsin",
            value: "WI"
        },
        {
            label: "Wyoming",
            value: "WY"
        }
    ]

    const handleState = (e) => {
        setTax({
            ...Tax,
            state: e.value,
        });
    };


    const handleAddTax = (e) => {
        e.preventDefault();
        if (Tax.state === '') {
            toast.error('Please enter tax state ');
            return;
        } else if (Tax.tax === '') {
            toast.error('Please enter tax percentage ');
            return;
        } else if (Tax.customerType === '') {
            toast.error('Please enter customer type ');
            return;
        } else if (Tax.url !== '') {
            dispatch(addTax(Tax));
        }
        // toast.success('Tax added');
    }

    const handleUpdateTax = (e) => {
        e.preventDefault();
    }


    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    loading ? (<PulseLoader />) : (
                        <div className="row p-5 justify-content-center">
                            <div className="col-md-5">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">{FormName}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label required"
                                                            htmlFor="basic-default-fullname"
                                                        >
                                                            State
                                                        </label>
                                                        <Select
                                                            options={selectState}
                                                            onChange={handleState}
                                                            value={
                                                                Tax.state === undefined
                                                                    ? ""
                                                                    : selectState.find((option) => {
                                                                        return (
                                                                            option.value === Tax.state
                                                                        );
                                                                    })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <InputField label="Tax Percentage" name='tax' value={Tax.tax} onChange={handleInputChange} required={true} type='text' placeholder="EX: 5" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label required"
                                                            htmlFor="basic-default-fullname"
                                                        >
                                                            Customer Type
                                                        </label>
                                                        <Select
                                                            options={selectCustomer}
                                                            onChange={handleCustomer}
                                                            value={
                                                                Tax.customerType === undefined
                                                                    ? ""
                                                                    : selectCustomer.find((option) => {
                                                                        return (
                                                                            option.value === Tax.customerType
                                                                        );
                                                                    })
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            {
                                                Editable === 'true' ? <button type="submit" onClick={handleUpdateTax} className="btn btn-primary">
                                                    Update Tax
                                                </button> : <button type="submit" onClick={handleAddTax} className="btn btn-primary">
                                                    Save
                                                </button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default TaxForm