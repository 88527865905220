import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
// import ImageField from '../components/InputFields/ImageField';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import { toast } from 'react-hot-toast';


const { addModel, updateModel } = require('../actions/modelActions');

const ModelForm = (props) => {
    const { id } = useParams();

    const { models } = useSelector(state => state.Models);

    const redirect = useNavigate();
    const dispatch = useDispatch();
    const { FormName, Editable } = props;
    const { Loading } = useSelector(state => state.Category);
    const { brands } = useSelector(state => state.Brand);
    const [mod, setMod] = useState({});
    const [model, setModel] = useState({
        name: "",
        brand: "",
    });

    const selectOptions = brands.map((brand) => {
        return { value: brand._id, label: brand.name };
    });

    useEffect(() => {
        if (id) {
            // eslint-disable-next-line
            const model = models.find(category => category.id == id);
            if (model) {
                setMod(model);
                setModel({
                    name: model.name,
                    brand: model.brand,
                });
            }
        }

    }, [id, models]);

    const handledropChange = (e) => {
        setModel({ ...model, brand: e.value });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModel({ ...model, [name]: value });
    };

    const handleAddSubCategory = (e) => {
        e.preventDefault();
        if (model.name === '') {
            toast.error('Please enter sub category name');
            return;
        } else if (model.brand === '') {
            toast.error('Please select category');
            return;
        } else if (model.name !== '' && model.brand !== '') {
            dispatch(addModel(model));
            redirect('/models');
        }
    }

    const handleUpdateSubCategory = (e) => {
        e.preventDefault();
        dispatch(updateModel(mod._id, model));
        redirect('/models');
    }

    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    Loading ? (<PulseLoader />) : (
                        <div className="row p-4 justify-content-center">
                            <div className="col-md-5">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">{FormName}</h5>
                                    </div>
                                    <div className="card-body">
                                        <form  >
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <InputField label="Name" name='name' value={model.name} onChange={handleInputChange} required={true} type='text' placeholder="Name here..." />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label required"
                                                            htmlFor="basic-default-fullname"
                                                        >
                                                            Brand
                                                        </label>
                                                        <Select
                                                            options={selectOptions}
                                                            onChange={handledropChange}
                                                            value={
                                                                model.brand === null
                                                                    ? ""
                                                                    : selectOptions.find((option) => {
                                                                        return (
                                                                            option.value === model.brand?._id
                                                                        );
                                                                    })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                Editable === 'true' ? <button type="submit" onClick={handleUpdateSubCategory} className="btn btn-primary">
                                                    Update Model
                                                </button> : <button type="submit" onClick={handleAddSubCategory} className="btn btn-primary">
                                                    Save
                                                </button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default ModelForm