import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST;

export const getADs = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_ADS_REQUEST" });
            const { data } = await axios.get(`${API_URL}/ads`);
            dispatch({ type: "GET_ADS_SUCCESS", ads: data });
        } catch (error) {
            dispatch({ type: "GET_ADS_FAILURE", error: error.message });

        }
    };
}

export const addAD = (ad) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADD_AD_REQUEST" });
            const { data } = await axios.post(`${API_URL}/ads`, ad, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "ADD_AD_SUCCESS",
                ad: data.createdAd,
            });
            toast.success('AD added successfully!');
        } catch (error) {
            dispatch({ type: "ADD_AD_FAILURE", error: error.message });
            toast.error('AD addition failed!');
        }
    };
}

export const deleteAD = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "DELETE_AD_REQUEST" });
            await axios.delete(`${API_URL}/ads/${id}`, {
                headers: { "x-auth-token": `${localStorage.getItem("token")}` },
            });
            dispatch({ type: "DELETE_AD_SUCCESS", id });
            toast.success('AD deleted successfully!');
        } catch (error) {
            dispatch({ type: "DELETE_AD_FAILURE", error: error.message });
            toast.error('AD deletion failed!');
        }
    };
}

export const updateAD = (id, ad) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_AD_REQUEST" });
            const { data } = await axios.put(`${API_URL}/ads/${id}`, ad, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });

            dispatch({ type: "UPDATE_AD_SUCCESS", ad: data });
            toast.success('AD updated successfully!');
        } catch (error) {
            dispatch({ type: "UPDATE_AD_FAILURE", error: error.message });
            toast.error('AD updation failed!');
        }
    };
}


export function setRedirecting() {
    return async dispatch => {
        dispatch({ type: 'SET_REDIRECTING' });
    }
}